import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'
import { getAuth } from "firebase/auth";
import { useContext, createContext } from 'react'

export const firebaseApp = initializeApp({
    apiKey: "AIzaSyDau-s63LRkuawK6eyYiQrA_SK5vcLuUK4",
    authDomain: "arquivocontabilidade-5ea6b.firebaseapp.com",
    projectId: "arquivocontabilidade-5ea6b",
    storageBucket: "arquivocontabilidade-5ea6b.appspot.com",
    messagingSenderId: "859121685562",
    appId: "1:859121685562:web:ebf401c7ddcbf6d508d103"
})

export const auth = getAuth()
export const db = getFirestore();
export const storage = getStorage();

export const AuthContext = createContext()

export const useAuthState = () => {
    const auth = useContext(AuthContext)
    return { ...auth, isAuthenticated: auth != null }
}
