import React, { useState, useContext, useEffect, useRef } from "react";
import { GlobalContext } from "../contexts/global";
import { Button, Row, Col, Container, Modal } from "react-bootstrap";
import DataTable from 'react-data-table-component';
import LogoArquivo from '../images/logo.svg';
import { isCNPJ, formatToCNPJ } from 'brazilian-values';

import {
    Box,
    Conteudo,
    Linha,
    Column
} from "../styles/FooterStyles";
import { useNavigate } from "react-router-dom";

function Footer() {

    const Navigate = useNavigate()

    const { usuario, SalvarLogin, DefineTipoAcesso, tipoAcesso, DefineLicenca, conexao } = useContext(GlobalContext)

    const [loading, setLoading] = useState(false)
    const [msgLoading, setMsgLoading] = useState('')

    const razao = localStorage.getItem('razao')
    const empresa = localStorage.getItem('empresa')
    const estabelecimento = localStorage.getItem('estabelecimento')

    const [empresasUsuario, setEmpresasUsuario] = useState([])
    const [telaSelecaoEmpresas, setTelaSelecaoEmpresas] = useState(false)

    const [telaAlerta, setTelaAlerta] = useState(false)
    const [telaMsg, setTelaMsg] = useState('')
    const [telaTitulo, setTelaTitulo] = useState('')


    function ExibirAlerta(titulo, mensagem) {
        setTelaMsg(mensagem)
        setTelaTitulo(titulo)
        setTelaAlerta(true)
    }

    function FormataCNPJ(CNPJ) {
        return formatToCNPJ(CNPJ);
    }

    useEffect(() => {
        if (tipoAcesso === 'CLIENTE') {
            if (!razao) {
                ListarEmpresasPorEmail(usuario.email)
            }
        }
    }, [])


    async function ListarEmpresasPorEmail(email) {

        setMsgLoading('Obtendo Empresas')
        setLoading(true)

        const url = conexao.endereco + '/Empresas/' + email

        let requisicao = new XMLHttpRequest()

        requisicao.timeout = 8000
        requisicao.open('GET', url, true)
        requisicao.setRequestHeader('Content-Type', 'application/json')
        requisicao.setRequestHeader('Authorization', conexao.autenticacao)
        requisicao.send()

        requisicao.ontimeout = function () {
            console.log('TimeOut na Requisição: ListarEmpresasPorEmail')
            setLoading(false)
            ExibirAlerta('Tempo Limite Excedido', 'Requisição: ListarEmpresasPorEmail')
        }

        requisicao.onerror = function () {
            console.log('Erro na Requisição: ListarEmpresasPorEmail: ' + this.response)
            setLoading(false)
            ExibirAlerta('Falha na Requisição', 'Verifique Suporte')
        }

        requisicao.onload = function () {
            setLoading(false)
            console.log('RESPONSE: ' + this.response)
            let resposta = JSON.parse(this.response)
            if (requisicao.status === 200) {
                localStorage.setItem('empresas', JSON.stringify(resposta))
                if (!razao) {
                    const empresas = localStorage.getItem('empresas')
                    setEmpresasUsuario(JSON.parse(empresas))
                    setTelaSelecaoEmpresas(true)
                }
            } else {
                alert('Falha ao Listar Empresas do Usuário')
            }

        }
    }

    function ExibeEmpresasUsuario() {

        const colunas = [
            {
                name: 'Nome Fantasia',
                selector: row => row.nome,
                sortable: true,
                minWidth: '30%'
            },
            {
                name: 'Razão Social',
                selector: row => row.razao,
                sortable: true,
                minWidth: '40%'
            },
            {
                name: 'CNPJ',
                selector: row => formatToCNPJ(row.cnpj),
                sortable: true,
                wrap: true
            },
            {
                name: 'Questor Empresa',
                selector: row => row.questor_empresa,
                sortable: true,
                wrap: true,
                omit: true
            },
            {
                name: 'Questor Estabelecimento',
                selector: row => row.questor_estabelecimento,
                sortable: true,
                wrap: true,
                omit: true
            }
        ];

        const paginationComponentOptions = {
            rowsPerPageText: 'Registros por página',
            rangeSeparatorText: 'de',
            selectAllRowsItem: true,
            selectAllRowsItemText: 'Todos',
        };

        return (
            <DataTable
                columns={colunas}
                data={empresasUsuario}
                onRowClicked={(row, index) => {
                    setTelaSelecaoEmpresas(false)
                    localStorage.setItem('razao', row.razao)
                    localStorage.setItem('empresa', row.questor_empresa)
                    localStorage.setItem('estabelecimento', row.questor_estabelecimento)
                    Navigate('/')
                }}
                // expandOnRowClicked={true}
                striped={true}
                highlightOnHover={true}
                responsive={true}
                pointerOnHover={true}
                noDataComponent={"Nenhum Registro Encontrado =/"}
                pagination={true}
                paginationComponentOptions={paginationComponentOptions}
            />
        )
    }


    return (
        <Box>
            {/* <Conteudo> */}
            <Container style={{ paddingLeft: 50 }}>
                <Row lg={12} sm={12} style={{ display: 'flex', alignItems: 'space-between' }}>
                    <Col lg={6} sm={12}>
                        <strong>Razão Social: &nbsp;</strong>{razao}
                    </Col>
                    <Col lg={2} sm={12}>
                        <strong>Empresa: &nbsp;</strong>{empresa}
                    </Col>
                    <Col lg={2} sm={12}>
                        <strong>Estabelecimento: &nbsp;</strong>{estabelecimento}
                    </Col>
                    <Col lg={2} sm={12} style={{ justifyContent: 'flex-end' }}>
                        <Button
                            style={{ backgroundColor: '#E30613', border: 0 }}
                            size='sm'
                            onClick={() => {
                                const empresas = localStorage.getItem('empresas')
                                setEmpresasUsuario(JSON.parse(empresas))
                                setTelaSelecaoEmpresas(true)
                            }}
                        >
                            Trocar Empresa
                        </Button>
                    </Col>
                </Row>
            </Container>
            {/* </Conteudo> */}

            <Modal show={telaSelecaoEmpresas} centered dialogClassName='modal-50'>
                <Modal.Header style={{ color: '#E30613', fontSize: 24 }}>
                    Selecione a Empresa
                </Modal.Header>
                <Modal.Body >
                    <Row>
                        <Col>
                            <ExibeEmpresasUsuario />
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {razao &&
                        <Button style={{ backgroundColor: '#E30613', border: 0 }} onClick={() => {
                            setTelaSelecaoEmpresas(false)
                        }}>Sair</Button>
                    }
                </Modal.Footer>
            </Modal>

            <Modal show={loading} centered>
                <Modal.Body style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 24 }}>
                    {/* <Spinner animation="border" style={{ color: "#E30613" }} /> */}
                    <img
                        src={LogoArquivo}
                        width={'20%'}
                        alt={'Imagem de Nuvem'}
                        loading={"lazy"}
                    ></img>
                </Modal.Body>
                <Modal.Footer style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {msgLoading}
                </Modal.Footer>
            </Modal>

            <Modal show={telaAlerta} centered>
                <Modal.Header style={{ color: '#E30613', fontSize: 24 }}>
                    {telaTitulo}
                </Modal.Header>
                <Modal.Body style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {telaMsg}
                </Modal.Body>
                <Modal.Footer style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Button style={{ backgroundColor: '#E30613', border: 0 }} onClick={() => { setTelaAlerta(false) }}>OK</Button>
                </Modal.Footer>
            </Modal>

        </Box >

    )
}

export default Footer