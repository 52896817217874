import styled from 'styled-components';

export const Box = styled.div`
  padding: 5px;
  background: #fff;
  position: fixed;
  bottom: 0;
  width: 100%;
  box-shadow: 1px -1px 1px 1px rgba(0, 0, 0, 0.2);
  
   
  @media (max-width: 1000px) {
    padding: 20px
  }
`;

export const Conteudo = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    height: 40px;
    /* background: red; */
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-left: 60px;
`;

export const Linha = styled.div`
  display: flex;
  /* justify-content: 'center'; */
  /* align-items: 'center'; */
  /* text-align: 'center'; */
`;

export const FooterLink = styled.a`
  color: #fff;
  margin-bottom: 20px;
  font-size: 18px;
  text-decoration: none;
   
  &:hover {
      color: green;
      transition: 200ms ease-in;
  }
`;

export const Heading = styled.p`
  font-size: 24px;
  color: #fff;
  margin-bottom: 40px;
  font-weight: bold;
`;