import React, { useState, useContext, useEffect, useRef } from "react";
import { useNavigate, } from 'react-router-dom';
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword, sendEmailVerification } from "firebase/auth";
import { collection, getDocs } from "firebase/firestore";
import { msgAviso } from "./sweet";
import { auth, db } from "../firebase";
import { GlobalContext } from '../contexts/global';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import LogoArquivo from '../images/logo.svg';
import DataTable from 'react-data-table-component';


import { Navbar, Nav, Container, Modal, Form, Button, ToastContainer, Toast, Col, Row } from 'react-bootstrap'


function Navegacao() {

    const Navigate = useNavigate()

    const [loading, setLoading] = useState(false)
    const [msgLoading, setMsgLoading] = useState('')

    const [modalLogin, setModalLogin] = useState(false)
    const [modalCadastro, setModalCadastro] = useState(false)

    const [exibirToast, setExibirToast] = useState(false)
    const [msgToast, setMsgToast] = useState('')

    const [dadosLogin, setDadosLogin] = useState([])
    const [dadosCadastro, setDadosCadastro] = useState([])

    const [empresasUsuario, setEmpresasUsuario] = useState([])
    const [telaSelecaoEmpresas, setTelaSelecaoEmpresas] = useState(false)

    const [telaAlerta, setTelaAlerta] = useState(false)
    const [telaMsg, setTelaMsg] = useState('')
    const [telaTitulo, setTelaTitulo] = useState('')

    const campoSenha = useRef();

    const { usuario, SalvarLogin, DefineTipoAcesso, tipoAcesso, DefineLicenca, conexao } = useContext(GlobalContext)

    const aviso = withReactContent(Swal)

    function ExibirAlerta(titulo, mensagem) {
        setTelaMsg(mensagem)
        setTelaTitulo(titulo)
        setTelaAlerta(true)
    }


    function Acessar() {

        setModalLogin(false)
        setMsgLoading('Validando Acesso')
        setLoading(true)

        const auth = getAuth();
        signInWithEmailAndPassword(auth, dadosLogin.email, dadosLogin.senha)
            .then((userCredential) => {

                setLoading(false)
                const user = userCredential.user;
                console.log('Logado com Sucesso!')
                console.log('Email: ' + user.email)
                DefineTipoAcesso(user.email)
                CarregaLicenciamento()
                SalvarLogin(user.email)
                setMsgToast('Login Realizado com Sucesso!')
                setExibirToast(true)

            })
            .catch((error) => {
                setLoading(false)
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log('Erro: ' + errorCode + ' - ' + errorMessage)
                setModalLogin(true)
                msgAviso('Atenção', 'Acesso Negado', 'error', 'ok');

            })
    }

    function Sair() {
        auth
            .signOut()
            .then(() => {
                console.log('Deslogando da Conta')

                setMsgToast('Logoff realizado com sucesso...')
                setExibirToast(true)
                SalvarLogin(null)
                localStorage.removeItem('razao')
                localStorage.removeItem('empresa')
                localStorage.removeItem('estabelecimento')
                Navigate('/')
            })
            .catch(error => alert(error.message))

    }

    async function CarregaLicenciamento() {
        // setMsgLoading('Verificando Licença Connect')
        // setLoading(true)
        const getDados = await getDocs(collection(db, "licenciamento"))
        getDados.forEach((doc) => {
            const jsonLicenca = JSON.stringify(doc.data())
            const licencaAtual = JSON.parse(jsonLicenca)
            DefineLicenca(licencaAtual)
            // setLoading(false)
        }
        )
        // setLoading(false)
    };

    async function ListarEmpresasPorEmail(email) {

        setMsgLoading('Listando Empresas do Usuário')
        setLoading(true)

        const url = conexao.endereco + '/Empresas/' + email

        let requisicao = new XMLHttpRequest()

        requisicao.timeout = 8000
        requisicao.open('GET', url, true)
        requisicao.setRequestHeader('Content-Type', 'application/json')
        requisicao.setRequestHeader('Authorization', conexao.autenticacao)
        requisicao.send()

        requisicao.ontimeout = function () {
            console.log('TimeOut na Requisição: ListarEmpresasPorEmail')
            setLoading(false)
            ExibirAlerta('Tempo Limite Excedido', 'Requisição: ListarEmpresasPorEmail')
        }

        requisicao.onerror = function () {
            console.log('Erro na Requisição: ListarEmpresasPorEmail: ' + this.response)
            setLoading(false)
            ExibirAlerta('Falha na Requisição', JSON.stringify(this.response))
        }

        requisicao.onload = function () {
            setLoading(false)
            console.log('RESPONSE: ' + this.response)
            let resposta = JSON.parse(this.response)
            if (requisicao.status === 200) {
                setEmpresasUsuario(resposta)
                setTelaSelecaoEmpresas(true)

                // SalvarLogin(email)
                // setMsgToast('Login Realizado com Sucesso!')
                // setExibirToast(true)
            } else {
                alert('Falha ao Listar Empresas do Usuário')
            }

        }
    }

    function CadastrarUsuario() {

        if (dadosCadastro.senha.length > 5) {

            setModalCadastro(false)
            setMsgLoading('Cadastrando Usuário')
            setLoading(true)

            createUserWithEmailAndPassword(auth, dadosCadastro.email, dadosCadastro.senha)
                .then(userCredentials => {
                    const user = userCredentials.user;

                    const verificado = userCredentials.isEmailVerified;
                    console.log(user.email)
                    if (!verificado) {
                        setLoading(false)
                        sendEmailVerification(auth.currentUser)
                        msgAviso('Verificação de E-mail', 'Enviamos um e-mail de confirmação para você! clique no link enviado para ativar seu cadastro', 'info', 'OK')
                    }
                })
                .catch(error => {

                    let codigoErro = error.code
                    let textoErro = error.message
                    let msgTraduzida = ''

                    if (codigoErro == 'auth/email-already-in-use') {
                        msgTraduzida = 'E-mail Já Possuí Cadastro na Plataforma!'
                    }
                    if (codigoErro == 'auth/invalid-email') {
                        msgTraduzida = 'E-mail Inválido!'
                    }
                    if (msgTraduzida !== '') {
                        setLoading(false)
                        msgAviso('Falha no Cadastro', msgTraduzida, 'warning', 'OK')
                    } else {
                        setLoading(false)
                        msgAviso('Falha no Cadastro', codigoErro + ' - ' + textoErro, 'warning', 'OK')
                    }

                })
        } else {
            setLoading(false)
            msgAviso('Atenção!', 'A senha deve ter pelo menos 6 caracteres', 'info', 'OK')
        }
    }

    function ExibeEmpresasUsuario() {

        const colunas = [
            {
                name: 'Nome Fantasia',
                selector: row => row.nome,
                sortable: true,
            },
            {
                name: 'Razão Social',
                selector: row => row.razao,
                sortable: true,
            },
            {
                name: 'CNPJ',
                selector: row => row.cnpj,
                sortable: true,
                wrap: true
            },
            {
                name: 'Questor Empresa',
                selector: row => row.questor_empresa,
                sortable: true,
                wrap: true
            },
            {
                name: 'Questor Estabelecimento',
                selector: row => row.questor_estabelecimento,
                sortable: true,
                wrap: true
            }
        ];

        const paginationComponentOptions = {
            rowsPerPageText: 'Registros por página',
            rangeSeparatorText: 'de',
            selectAllRowsItem: true,
            selectAllRowsItemText: 'Todos',
        };

        return (
            <DataTable
                columns={colunas}
                data={empresasUsuario}
                onRowClicked={(row, index) => {
                    setTelaSelecaoEmpresas(false)
                    localStorage.setItem('razao', row.razao)
                    localStorage.setItem('empresa', row.questor_empresa)
                    localStorage.setItem('estabelecimento', row.questor_estabelecimento)
                    setMsgToast('Login Realizado com Sucesso!')
                    SalvarLogin(dadosLogin.email)
                    setExibirToast(true)
                }}
                // expandOnRowClicked={true}
                striped={true}
                highlightOnHover={true}
                responsive={true}
                pointerOnHover={true}
                noDataComponent={"Nenhum Registro Encontrado =/"}
                pagination={true}
                paginationComponentOptions={paginationComponentOptions}
            />
        )
    }


    useEffect(() => {
        setMsgLoading('Validando Acesso')
        setLoading(true)
        console.log('Chegou no useEffect da Navbar')
        const conectado = auth.onAuthStateChanged(user => {
            if (user) {
                SalvarLogin(user.email)
                DefineTipoAcesso(user.email)
                CarregaLicenciamento()
                setLoading(false)
            } else {
                SalvarLogin(null)
                setModalLogin(true)
                setLoading(false)
            }
        })

        return conectado;

    }, [])

    return (
        <>
            <Navbar className="barra-dash" expand="lg" collapseOnSelect>
                <Container>
                    <Navbar.Brand className="titulo-dash" onClick={() => { Navigate("/") }} style={{ margin: 0 }}>
                        <img
                            src={LogoArquivo}
                            width={'100%'}
                            alt={'Imagem de Nuvem'}
                            loading={"lazy"}
                        ></img>
                    </Navbar.Brand>

                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        {tipoAcesso === 'CLIENTE' &&
                            <Nav className="me-auto">
                                {!usuario.email &&
                                    <>
                                        <Nav.Link style={{ padding: 20 }} onClick={() => {
                                            setDadosLogin({ ...dadosLogin, email: '', senha: '' })
                                            setModalLogin(true)
                                        }}>Acessar</Nav.Link>
                                    </>
                                }
                                {usuario.email &&
                                    <>
                                        <Nav.Link style={{ padding: 20 }} onClick={() => {
                                            Navigate('/')
                                        }}>Dashboard</Nav.Link>
                                        <Nav.Link style={{ padding: 20 }} onClick={() => { Navigate('/notas-fiscais') }} eventKey="1" >Notas Fiscais</Nav.Link>
                                        <Nav.Link style={{ padding: 20 }} onClick={() => { Navigate('/impostos') }} eventKey="2" >Impostos</Nav.Link>
                                        <Nav.Link style={{ padding: 20 }} onClick={() => { Navigate('/folha-pagamento') }} eventKey="3" >Folha de Pagamento</Nav.Link>
                                        <Nav.Link style={{ padding: 20 }} onClick={() => { Navigate('/movimento-bancario') }} eventKey="4" >Mvto Bancário</Nav.Link>
                                        <Nav.Link style={{ padding: 20 }} onClick={() => { Navigate('/mais-opcoes') }} eventKey="5" >Mais Opções</Nav.Link>
                                        <Nav.Link style={{ padding: 20 }} onClick={() => { Sair() }}>Sair</Nav.Link>
                                    </>
                                }
                            </Nav>
                        }
                        {tipoAcesso === 'ADM' &&
                            <Nav className="me-auto">
                                {!usuario.email &&
                                    <>
                                        <Nav.Link style={{ padding: 20 }} onClick={() => {
                                            setDadosLogin({ ...dadosLogin, email: '', senha: '' })
                                            setModalLogin(true)
                                        }}>Acessar</Nav.Link>
                                    </>
                                }
                                {usuario.email &&
                                    <>
                                        <Nav.Link style={{ padding: 20 }} onClick={() => {
                                            Navigate('/')
                                        }}>Dashboard</Nav.Link>
                                        <Nav.Link style={{ padding: 20 }} onClick={() => { Navigate('/adm/cadastros/empresas') }} eventKey="1" >Empresas</Nav.Link>
                                        <Nav.Link style={{ padding: 20 }} onClick={() => { Navigate('/adm/solicitacoes') }} eventKey="2" >Solicitações</Nav.Link>
                                        {/* <Nav.Link style={{ padding: 20 }} onClick={() => { Navigate('/folha-pagamento') }} eventKey="3" >Folha de Pagamento</Nav.Link>
                                        <Nav.Link style={{ padding: 20 }} onClick={() => { Navigate('/movimento-bancario') }} eventKey="4" >Mvto Bancário</Nav.Link>
                                        <Nav.Link style={{ padding: 20 }} onClick={() => { Navigate('/mais-opcoes') }} eventKey="5" >Mais Opções</Nav.Link> */}
                                        <Nav.Link style={{ padding: 20 }} onClick={() => { Sair() }}>Sair</Nav.Link>
                                    </>
                                }
                            </Nav>
                        }
                    </Navbar.Collapse>
                </Container>

            </Navbar>

            <ToastContainer className="p-3" position={"top-end"} style={{ zIndex: 20 }}>
                <Toast onClose={() => { setExibirToast(false) }} show={exibirToast} delay={3000} autohide>
                    <Toast.Header closeButton={true}>
                        <img
                            src="holder.js/20x20?text=%20"
                            className="rounded me-2"
                            alt=""
                        />
                        <strong className="me-auto">{msgToast}</strong>
                        <small>agora</small>
                    </Toast.Header>

                </Toast>
            </ToastContainer>

            <Modal //Modal Login da Plataforma
                show={modalLogin}
                centered
            >
                <Modal.Header>
                    <Modal.Title>Acessar Plataforma</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="formLogEmail">
                            <Form.Label>E-mail</Form.Label>
                            <Form.Control type="text" placeholder="E-mail" autoFocus onKeyUp={(evento) => { if (evento.key === 'Enter') { campoSenha.current.focus() } }} value={dadosLogin.email} onChange={(e) => { setDadosLogin({ ...dadosLogin, email: e.target.value }) }} />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formLogSenha">
                            <Form.Label>Senha</Form.Label>
                            <Form.Control type="password" placeholder="Senha" ref={campoSenha} onKeyUp={(evento) => { if (evento.key === 'Enter') { Acessar() } }} value={dadosLogin.senha} onChange={(e) => { setDadosLogin({ ...dadosLogin, senha: e.target.value }) }} />
                        </Form.Group>

                    </Form>
                </Modal.Body>

                <Modal.Footer>
                    <Button style={{ backgroundColor: '#000000', border: 0 }} onClick={() => { setModalLogin(false) }}>Fechar</Button>
                    <Button style={{ backgroundColor: '#E30613', border: 0 }} onClick={() => { Acessar() }}>Acessar</Button>
                </Modal.Footer>
            </Modal>

            <Modal //Modal Cadastro Usuário
                show={modalCadastro}

            // dialogClassName="modal-75"
            >
                <Modal.Header>
                    <Modal.Title>Cadastre-se</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="formCadEmail">
                            <Form.Label>E-mail</Form.Label>
                            <Form.Control type="text" placeholder="E-mail" value={dadosCadastro.email} onChange={(e) => { setDadosCadastro({ ...dadosCadastro, email: e.target.value }) }} />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formCadSenha">
                            <Form.Label>Senha</Form.Label>
                            <Form.Control type="password" placeholder="Senha" value={dadosCadastro.senha} onChange={(e) => { setDadosCadastro({ ...dadosCadastro, senha: e.target.value }) }} />
                        </Form.Group>

                    </Form>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={() => { setModalCadastro(false) }}>Fechar</Button>
                    <Button variant="success" onClick={() => { CadastrarUsuario() }}>Cadastrar</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={loading} centered>
                <Modal.Body style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 24 }}>
                    {/* <Spinner animation="border" style={{ color: "#E30613" }} /> */}
                    <img
                        src={LogoArquivo}
                        width={'20%'}
                        alt={'Imagem de Nuvem'}
                        loading={"lazy"}
                    ></img>
                </Modal.Body>
                <Modal.Footer style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {msgLoading}
                </Modal.Footer>
            </Modal>

            <Modal show={telaSelecaoEmpresas} centered dialogClassName='modal-50'>
                <Modal.Header style={{ color: '#E30613', fontSize: 24 }}>
                    Selecione a Empresa
                </Modal.Header>
                <Modal.Body >
                    <Row>
                        <Col>
                            <ExibeEmpresasUsuario />
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Button style={{ backgroundColor: '#E30613', border: 0 }} onClick={() => {
                        Sair()
                        setTelaSelecaoEmpresas(false)
                    }}>Sair</Button>
                </Modal.Footer>
            </Modal>


        </>

    )
}

export default Navegacao;