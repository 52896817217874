import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import React from "react";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import GlobalProvider from './contexts/global'

//Página Home, Igual para Cliente e Adm
import Home from './pages/Home';

//Páginas Acesso Cliente
import PaginaDetalhe from './pages/PaginaDetalhe';
import NaoEncontrado from './pages/NaoEncontrato';

//Páginas Acesso Adm
import Solicitacoes from './pages/adm/solicitacoes/solicitacoes'
import CadastroEmpresas from './pages/adm/cadastros/empresas';
import CadastroUsuarios from './pages/adm/cadastros/usuarios';

import ImportarExtrato from './pages/MvtoBancario/ImportarExtrato';
import SolicitacoesUsuario from './pages/Solicitacoes/SolicitacoesUsuario';
import ListaFuncionarios from './pages/FolhaPagamento/ListaFuncionarios';

function App() {
  return (
    <GlobalProvider>
      <BrowserRouter>

        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/notas-fiscais" element={<PaginaDetalhe props={{ titulo: 'Notas Fiscais' }} />} />
          <Route exact path="/impostos" element={<PaginaDetalhe props={{ titulo: 'Impostos' }} />} />

          <Route exact path="/folha-pagamento" element={<PaginaDetalhe props={{ titulo: 'Folha de Pagamento' }} />} />
          <Route exact path="/folha-pagamento/lista-funcionarios" element={<ListaFuncionarios />} />

          <Route exact path="/movimento-bancario" element={<PaginaDetalhe props={{ titulo: 'Movimento Bancário' }} />} />
          <Route exact path="/movimento-bancario/importar-extrato-ofx" element={<ImportarExtrato />} />

          <Route exact path="/mais-opcoes" element={<PaginaDetalhe props={{ titulo: 'Mais Opções' }} />} />
          <Route exact path="/mais-opcoes/solicitacoes" element={<SolicitacoesUsuario />} />

          <Route exact path="/adm/solicitacoes" element={<Solicitacoes />} />
          <Route exact path="/adm/cadastros/empresas" element={<CadastroEmpresas />} />
          <Route exact path="/adm/cadastros/empresas/:cnpj" element={<CadastroUsuarios />} />
          <Route path="*" element={<NaoEncontrado />} />
        </Routes>

      </BrowserRouter>
    </GlobalProvider>
  );
}

export default App;
