import React, { useEffect, useState, useContext } from "react";
import Navegacao from "../../../components/navbar";
import Footer from "../../../components/footer";
import { useNavigate } from "react-router-dom"
import { Col, Row, Container, Button, Modal, Form } from 'react-bootstrap'
import LogoArquivo from '../../../images/logo.svg';
import { GlobalContext } from "../../../contexts/global";
import DataTable from 'react-data-table-component';
import { MdEdit, MdDelete } from 'react-icons/md'
import { TbListDetails } from 'react-icons/tb'

function CadastroEmpresas() {

    const Navigate = useNavigate()

    const [loading, setLoading] = useState(false)
    const [msgLoading, setMsgLoading] = useState('')

    const { usuario, tipoAcesso, conexao, licenca } = useContext(GlobalContext)

    const [telaAlerta, setTelaAlerta] = useState(false)
    const [telaMsg, setTelaMsg] = useState('')
    const [telaTitulo, setTelaTitulo] = useState('')

    const [empresas, setEmpresas] = useState([])
    const [telaCadastro, setTelaCadastro] = useState(false)

    const [modoEdicao, setModoEdicao] = useState(false)
    const [idExclusao, setIdExclusao] = useState('0')

    const [dadosCadastro, setDadosCadastro] = useState([])

    function ExibirAlerta(titulo, mensagem) {
        setTelaMsg(mensagem)
        setTelaTitulo(titulo)
        setTelaAlerta(true)
    }

    function ExibirExclusao(cnpj) {
        setTelaTitulo('Confirma Exclusão?')
        setTelaMsg('A Ação não poderá ser desfeita!')
        setIdExclusao(cnpj)
        setTelaAlerta(true)
    }

    function EditarCadastro(nome, razao, cnpj, questor_empresa, questor_estabelecimento) {
        setDadosCadastro({
            nome: nome,
            razao: razao,
            cnpj: cnpj,
            questor_empresa: questor_empresa,
            questor_estabelecimento: questor_estabelecimento
        })
        setModoEdicao(true)
        setTelaCadastro(true)
    }

    function ExibeDados() {

        const colunas = [
            {
                name: 'Nome Fantasia',
                selector: row => row.nome,
                sortable: true,
            },
            {
                name: 'Razão Social',
                selector: row => row.razao,
                sortable: true,
            },
            {
                name: 'CNPJ',
                selector: row => row.cnpj,
                sortable: true,
                wrap: true
            },
            {
                name: 'Questor Empresa',
                selector: row => row.questor_empresa,
                sortable: true,
                wrap: true
            },
            {
                name: 'Questor Estabelecimento',
                selector: row => row.questor_estabelecimento,
                sortable: true,
                wrap: true
            },
            {
                name: 'Cadastro',
                selector: row => row.data_cadastro,
                sortable: true,
                wrap: true
            },
            {
                name: 'Ações',
                button: true,
                center: true,
                cell: row => (
                    <>
                        < a onClick={() => { Navigate('/adm/cadastros/empresas/' + row.cnpj, { state: { nome: row.nome, razao: row.razao, idEmpresa: row.id } }) }} style={{ cursor: 'pointer' }}>
                            <TbListDetails size="24" color="#E30613" title="Usuários" />
                        </a>
                        < a onClick={() => { EditarCadastro(row.nome, row.razao, row.cnpj, row.questor_empresa, row.questor_estabelecimento) }} style={{ marginLeft: 5, cursor: 'pointer' }}>
                            <MdEdit size="24" color="#E30613" title="Editar" />
                        </a>
                        < a onClick={() => { ExibirExclusao(row.cnpj) }} style={{ marginLeft: 5, cursor: 'pointer' }}>
                            <MdDelete size="24" color="#E30613" title="Excluir" />
                        </a>
                    </>

                ),
            },

        ];

        const paginationComponentOptions = {
            rowsPerPageText: 'Registros por página',
            rangeSeparatorText: 'de',
            selectAllRowsItem: true,
            selectAllRowsItemText: 'Todos',
        };


        return (
            <DataTable
                columns={colunas}
                data={empresas}
                // onRowClicked={(row, index) => { Navigate('/adm/cadastros/empresas/' + row.cnpj, { state: { nome: row.nome, razao: row.razao } }) }}
                // expandOnRowClicked={true}
                striped={true}
                highlightOnHover={true}
                responsive={true}
                // pointerOnHover={true}
                noDataComponent={"Nenhum Registro Encontrado =/"}
                pagination={true}
                paginationComponentOptions={paginationComponentOptions}
            />
        )
    }


    async function ListarEmpresas() {

        setMsgLoading('Listando Empresas')
        setLoading(true)

        const url = conexao.endereco + '/Empresas'
        console.log('URL Listar Empresas:' + url)

        let requisicao = new XMLHttpRequest()

        requisicao.timeout = 8000
        requisicao.open('GET', url, true)
        requisicao.setRequestHeader('Content-Type', 'application/json')
        requisicao.setRequestHeader('Authorization', conexao.autenticacao)
        requisicao.send()

        requisicao.ontimeout = function () {
            console.log('TimeOut na Requisição: ListarEmpresas')
            setLoading(false)
            ExibirAlerta('Tempo Limite Excedido', 'Requisição: ListarEmpresas')
        }

        requisicao.onerror = function () {
            console.log('Erro na Requisição: ListarEmpresas: ' + this.response)
            setLoading(false)
            ExibirAlerta('Falha na Requisição', 'Verifique Suporte')
        }

        requisicao.onload = function () {
            setLoading(false)
            console.log('RESPONSE: ' + this.response)
            if (requisicao.status === 200) {
                setEmpresas(JSON.parse(this.response))
            }
            if (requisicao.status === 204) {
                setEmpresas([])
            }
            if (requisicao.status === 400) {
                ExibirAlerta('Falha ao Listar Empresas', JSON.stringify(this.response))
            }
        }
    }

    async function CadastrarEmpresa() {

        setTelaCadastro(false)

        setMsgLoading('Cadastrando Empresa')
        setLoading(true)

        const url = conexao.endereco + '/Empresas/'

        let body = {
            "nome": dadosCadastro.nome,
            "razao": dadosCadastro.razao,
            "cnpj": dadosCadastro.cnpj,
            "questor_empresa": dadosCadastro.questor_empresa,
            "questor_estabelecimento": dadosCadastro.questor_estabelecimento,
        }

        let requisicao = new XMLHttpRequest()

        requisicao.timeout = 8000
        requisicao.open('POST', url, true)
        requisicao.setRequestHeader('Content-Type', 'application/json')
        requisicao.setRequestHeader('Authorization', conexao.autenticacao)
        requisicao.send(JSON.stringify(body))

        requisicao.ontimeout = function () {
            console.log('TimeOut na Requisição: ListarEmpresas')
            setLoading(false)
            ExibirAlerta('Tempo Limite Excedido', 'Requisição: ListarEmpresas')
        }

        requisicao.onerror = function () {
            console.log('Erro na Requisição: ListarEmpresas: ' + this.response)
            setLoading(false)
            ExibirAlerta('Falha na Requisição', 'Verifique Suporte')
        }

        requisicao.onload = function () {
            setLoading(false)
            console.log('RESPONSE: ' + this.response)
            if (requisicao.status === 201) {
                ExibirAlerta('Parabéns', 'Cadastro Realizado Com Sucesso!')
                setDadosCadastro([])
                ListarEmpresas()
            }
            if (requisicao.status === 400) {
                const resposta = JSON.parse(this.response)
                ExibirAlerta('Falha ao Cadastrar Empresa', resposta.status)
                setTelaCadastro(true)
            }
        }
    }

    async function AtualizarEmpresa() {

        setTelaCadastro(false)

        setMsgLoading('Atualizando Cadastro da Empresa')
        setLoading(true)

        const url = conexao.endereco + '/Empresas/' + dadosCadastro.cnpj

        let body = {
            "nome": dadosCadastro.nome,
            "razao": dadosCadastro.razao,
            "cnpj": dadosCadastro.cnpj,
            "questor_empresa": dadosCadastro.questor_empresa,
            "questor_estabelecimento": dadosCadastro.questor_estabelecimento,
        }

        console.log('Atualização de Cadastro: ' + JSON.stringify(body))

        let requisicao = new XMLHttpRequest()

        requisicao.timeout = 8000
        requisicao.open('PUT', url, true)
        requisicao.setRequestHeader('Content-Type', 'application/json')
        requisicao.setRequestHeader('Authorization', conexao.autenticacao)
        requisicao.send(JSON.stringify(body))

        requisicao.ontimeout = function () {
            console.log('TimeOut na Requisição: ListarEmpresas')
            setLoading(false)
            ExibirAlerta('Tempo Limite Excedido', 'Requisição: ListarEmpresas')
        }

        requisicao.onerror = function () {
            console.log('Erro na Requisição: ListarEmpresas: ' + this.response)
            setLoading(false)
            ExibirAlerta('Falha na Requisição', 'Verifique Suporte')
        }

        requisicao.onloadend = function () {
            setLoading(false)
            console.log('RESPONSE: ' + this.response)
            if (requisicao.status === 200) {
                ExibirAlerta('Parabéns', 'Cadastro Atualizado Com Sucesso!')
                setDadosCadastro([])
                ListarEmpresas()
            }
            if (requisicao.status === 400) {
                const resposta = JSON.parse(this.response)
                ExibirAlerta('Falha ao Cadastrar Empresa', resposta.status)
                setTelaCadastro(true)
            }
        }
    }

    async function DeletarEmpresa(cnpj) {

        setTelaCadastro(false)
        setTelaAlerta(false)
        setMsgLoading('Deletando Cadastro da Empresa')
        setLoading(true)

        const url = conexao.endereco + '/Empresas/' + cnpj

        let requisicao = new XMLHttpRequest()

        requisicao.timeout = 8000
        requisicao.open('DELETE', url, true)
        requisicao.setRequestHeader('Content-Type', 'application/json')
        requisicao.setRequestHeader('Authorization', conexao.autenticacao)
        requisicao.send()

        requisicao.ontimeout = function () {
            console.log('TimeOut na Requisição: ListarEmpresas')
            setLoading(false)
            ExibirAlerta('Tempo Limite Excedido', 'Requisição: ListarEmpresas')
            setIdExclusao('0')
        }

        requisicao.onerror = function () {
            console.log('Erro na Requisição: ListarEmpresas: ' + this.response)
            setLoading(false)
            ExibirAlerta('Falha na Requisição', 'Verifique Suporte')
            setIdExclusao('0')
        }

        requisicao.onload = function () {
            setLoading(false)
            console.log('RESPONSE: ' + this.response)
            if (requisicao.status === 200) {
                ExibirAlerta('Parabéns', 'Cadastro Removido Com Sucesso!')
                setDadosCadastro([])
                setIdExclusao('0')
                ListarEmpresas()
            }
            if (requisicao.status === 400) {
                const resposta = JSON.parse(this.response)
                ExibirAlerta('Falha ao Cadastrar Empresa', resposta.status)
                setIdExclusao('0')
            }
        }
    }

    useEffect(() => {
        if (tipoAcesso !== 'ADM') {
            Navigate('/')
        } else {
            ListarEmpresas()
        }

    }, [])

    return (
        <>
            <Navegacao />

            <div style={{ display: 'flex', backgroundColor: '#f5f5f5', width: '100%', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                <Container style={{ display: 'flex', backgroundColor: '#f5f5f5', width: '100%', flexDirection: 'column' }}>
                    <Col sm={12} lg={12} style={{ paddingTop: 20, paddingLeft: 25, paddingRight: 25 }}>
                        <Row>
                            <Col sm={12} lg={7} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom: 5 }}>
                                <h3>Empresas</h3><strong style={{ color: 'grey', fontSize: 14, marginLeft: 15 }}>{empresas.size}{Number(empresas.length)}/{licenca.empresas}</strong>
                            </Col>
                            <Col sm={12} lg={5} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginBottom: 5 }}>
                                <Button
                                    style={{ backgroundColor: '#E30613', border: 0 }}
                                    size='sm'
                                    onClick={() => {
                                        if (empresas.length >= licenca.empresas) {
                                            ExibirAlerta('Controle de Licenciamento', 'Limite de Cadastros Excedido')
                                        } else {
                                            setDadosCadastro([])
                                            setModoEdicao(false)
                                            setTelaCadastro(true)
                                        }
                                    }}
                                >
                                    Incluir
                                </Button>
                                <Button
                                    style={{ backgroundColor: '#E30613', border: 0, marginLeft: 15 }}
                                    size='sm'
                                    onClick={() => {
                                        ListarEmpresas()
                                    }}
                                >
                                    Atualizar
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                    <Row style={{ paddingLeft: 40, paddingRight: 40 }}>
                        <hr />
                    </Row>
                    <div style={{ paddingLeft: 25, paddingRight: 25 }} >
                        <Row>
                            <Col>
                                <ExibeDados />
                            </Col>
                        </Row>
                    </div>
                </Container>
                <Footer />

                <Modal show={loading} centered>
                    <Modal.Body style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 24 }}>
                        {/* <Spinner animation="border" style={{ color: "#E30613" }} /> */}
                        <img
                            src={LogoArquivo}
                            width={'20%'}
                            alt={'Imagem de Nuvem'}
                            loading={"lazy"}
                        ></img>
                    </Modal.Body>
                    <Modal.Footer style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {msgLoading}
                    </Modal.Footer>
                </Modal>

                <Modal show={telaAlerta} centered>
                    <Modal.Header style={{ color: '#E30613', fontSize: 24 }}>
                        {telaTitulo}
                    </Modal.Header>
                    <Modal.Body style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {telaMsg}
                    </Modal.Body>
                    <Modal.Footer style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {idExclusao === '0' &&
                            <Button style={{ backgroundColor: '#E30613', border: 0 }} onClick={() => { setTelaAlerta(false) }}>OK</Button>
                        }
                        {idExclusao !== '0' &&
                            <>
                                <Button variant="secondary" onClick={() => { setTelaAlerta(false) }}>Cancelar</Button>
                                <Button style={{ backgroundColor: '#E30613', border: 0 }} onClick={() => { DeletarEmpresa(idExclusao) }}>Confirmar</Button>
                            </>
                        }

                    </Modal.Footer>
                </Modal>

                <Modal show={telaCadastro} dialogClassName='modal-50'>
                    <Modal.Header>
                        {modoEdicao &&
                            <Modal.Title>Edição de Cadastro</Modal.Title>
                        }
                        {!modoEdicao &&
                            <Modal.Title>Cadastro de Empresa</Modal.Title>
                        }
                    </Modal.Header>

                    <Modal.Body>
                        <Form>
                            <Row>
                                <Col sm={12} lg={6}>
                                    <Form.Group className="mb-3" controlId="formCadNome">
                                        <Form.Label>Nome Fantasia</Form.Label>
                                        <Form.Control type="text" placeholder="Nome Fantasia" value={dadosCadastro.nome} onChange={(e) => { setDadosCadastro({ ...dadosCadastro, nome: e.target.value }) }} />
                                    </Form.Group>

                                </Col>
                                <Col sm={12} lg={6}>
                                    <Form.Group className="mb-3" controlId="formCadRazao">
                                        <Form.Label>Razão Social</Form.Label>
                                        <Form.Control type="text" placeholder="Razão Social" value={dadosCadastro.razao} onChange={(e) => { setDadosCadastro({ ...dadosCadastro, razao: e.target.value }) }} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12} lg={4}>
                                    <Form.Group className="mb-3" controlId="formCadCNPJ">
                                        <Form.Label>CNPJ</Form.Label>
                                        {modoEdicao &&
                                            <Form.Control type="text" disabled placeholder="CNPJ" value={dadosCadastro.cnpj} onChange={(e) => { setDadosCadastro({ ...dadosCadastro, cnpj: e.target.value }) }} />
                                        }
                                        {!modoEdicao &&
                                            <Form.Control type="text" placeholder="CNPJ" value={dadosCadastro.cnpj} onChange={(e) => { setDadosCadastro({ ...dadosCadastro, cnpj: e.target.value }) }} />
                                        }
                                    </Form.Group>

                                </Col>
                                <Col sm={12} lg={4}>
                                    <Form.Group className="mb-3" controlId="formCadIdIntegracao">
                                        <Form.Label>Questor: Empresa</Form.Label>
                                        <Form.Control type="text" placeholder="Empresa no Questor" value={dadosCadastro.questor_empresa} onChange={(e) => { setDadosCadastro({ ...dadosCadastro, questor_empresa: e.target.value }) }} />
                                    </Form.Group>
                                </Col>
                                <Col sm={12} lg={4}>
                                    <Form.Group className="mb-3" controlId="formCadIdIntegracao">
                                        <Form.Label>Questor: Estabelecimento</Form.Label>
                                        <Form.Control type="text" placeholder="Estabelecimento no Questor" value={dadosCadastro.questor_estabelecimento} onChange={(e) => { setDadosCadastro({ ...dadosCadastro, questor_estabelecimento: e.target.value }) }} />
                                    </Form.Group>
                                </Col>
                            </Row>

                        </Form>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => { setTelaCadastro(false) }}>Fechar</Button>
                        {modoEdicao &&
                            <Button style={{ backgroundColor: '#E30613', border: 0 }} onClick={() => { AtualizarEmpresa() }}>Atualizar</Button>
                        }
                        {!modoEdicao &&
                            <Button style={{ backgroundColor: '#E30613', border: 0 }} onClick={() => { CadastrarEmpresa() }}>Cadastrar</Button>
                        }
                    </Modal.Footer>
                </Modal>


            </div>
        </>
    )
}

export default CadastroEmpresas;