import React, { useState, useEffect, useContext } from "react"
import { useNavigate } from 'react-router-dom';
import DataTable from "react-data-table-component"
import { Container, Button, Form, Row, Col, Modal, Card, ProgressBar } from 'react-bootstrap'
import Navegacao from "../components/navbar"
import { auth, db, storage } from "../firebase"
import { GlobalContext } from '../contexts/global';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { msgAviso } from "../components/sweet";
import { RiUserSearchLine } from 'react-icons/ri'
import { BsArrowLeftRight, BsPiggyBank, BsFillPlusCircleFill } from 'react-icons/bs'
import { FaPercentage, FaFileContract, FaHistory } from 'react-icons/fa'
import { GoQuestion, GoKeyboard } from 'react-icons/go'
import { IoHelpBuoy } from 'react-icons/io5'
import { BiImport, BiExport } from 'react-icons/bi'
import { AiOutlineUnorderedList, AiOutlineFileSearch } from 'react-icons/ai'
import { MdCancel, MdEvent, MdEventAvailable, MdOutlinePendingActions } from 'react-icons/md'
import { HiOutlineReceiptTax } from 'react-icons/hi'
import Footer from '../components/footer'
import LogoArquivo from '../images/logo.svg'

function PaginaDetalhe({ props }) {


    useEffect(() => {
        console.log('Chegou no useEffect do Solicitações')

        auth.onAuthStateChanged(user => {
            if (user) {
                SalvarLogin(user.email)
            } else {
                SalvarLogin(null)
                Navigate('/')
            }
        })
    }, [])


    const Navigate = useNavigate()

    const [loading, setLoading] = useState(false)
    const [msgLoading, setMsgLoading] = useState('')

    const aviso = withReactContent(Swal)

    const { usuario, SalvarLogin } = useContext(GlobalContext)

    const menuNotasFiscais = [
        {
            "identificador": "como_emitir_nota_fiscal",
            "icone": "GoQuestion",
            "titulo": "Como Emitir Nota Fiscal",
        },
        {
            "identificador": "enviar_notas_fiscais",
            "icone": "BiExport",
            "titulo": "Enviar Notas Fiscais",
        },
        {
            "identificador": "consultar_notas_fiscais",
            "icone": "AiOutlineUnorderedList",
            "titulo": "Consultar Notas Fiscais",
        },
        {
            "identificador": "cancelar_nota_fiscal",
            "icone": "MdCancel",
            "titulo": "Cancelar Nota Fiscal",
        },
        {
            "identificador": "registrar_notas_tomadas",
            "icone": "FaFileContract",
            "titulo": "Registrar Notas Tomadas",
        },
        {
            "identificador": "consultar_aliquotas",
            "icone": "FaPercentage",
            "titulo": "Consultar Alíquotas",
        },
    ]

    const menuMovimentoBancario = [
        {
            "identificador": "enviar_extrato",
            "icone": "BiExport",
            "titulo": "Enviar Extrato Bancário",
        },
        // {
        //     "identificador": "conciliar_extratos",
        //     "icone": "BsArrowLeftRight",
        //     "titulo": "Conciliar Extratos",
        // },
    ]

    const menuFolhaPgto = [
        {
            "identificador": "consultar_folha",
            "icone": "AiOutlineFileSearch",
            "titulo": "Consultar Folha",
        },
        {
            "identificador": "listar_funcionarios",
            "icone": "RiUserSearchLine",
            "titulo": "Listar Funcionários",
        },
        {
            "identificador": "informar_eventos",
            "icone": "MdEvent",
            "titulo": "Informar Eventos",
        },
        {
            "identificador": "rh_admissao",
            "icone": "BsFillPlusCircleFill",
            "titulo": "Admissão Funcionário",
        },
    ]

    const menuImpostos = [
        {
            "identificador": "consultar_impostos",
            "icone": "HiOutlineReceiptTax",
            "titulo": "Consultar Impostos",
        },
        {
            "identificador": "historico_pagamento_impostos",
            "icone": "FaHistory",
            "titulo": "Histórico de Pagamentos",
        },
        {
            "identificador": "impostos_atraso",
            "icone": "MdOutlinePendingActions",
            "titulo": "Impostos em Atraso",
        },
        {
            "identificador": "solicitar_parcelamento",
            "icone": "BsPiggyBank",
            "titulo": "Solicitar Parcelaemtno",
        },
    ]

    const menuMaisOpcoes = [
        {
            "identificador": "solicitacoes",
            "icone": "IoHelpBuoy",
            "titulo": "Minhas Solicitações",
        },
        // {
        //     "identificador": "conciliar_extratos",
        //     "icone": "BsArrowLeftRight",
        //     "titulo": "Conciliar Extratos",
        // },
    ]


    function RenderizaIcone(props) {

        switch (props.icone) {
            case 'GoQuestion':
                return (<GoQuestion color="#E30614" size="36" />)
            case 'BiImport':
                return (<BiImport color="#E30614" size="36" />)
            case 'BiExport':
                return (<BiExport color="#E30614" size="36" />)
            case 'AiOutlineUnorderedList':
                return (<AiOutlineUnorderedList color="#E30614" size="36" />)
            case 'MdCancel':
                return (<MdCancel color="#E30614" size="36" />)
            case 'FaFileContract':
                return (<FaFileContract color="#E30614" size="36" />)
            case 'FaPercentage':
                return (<FaPercentage color="#E30614" size="36" />)
            case 'BsArrowLeftRight':
                return (<BsArrowLeftRight color="#E30614" size="36" />)
            case 'AiOutlineFileSearch':
                return (<AiOutlineFileSearch color="#E30614" size="36" />)
            case 'RiUserSearchLine':
                return (<RiUserSearchLine color="#E30614" size="36" />)
            case 'MdEvent':
                return (<MdEvent color="#E30614" size="36" />)
            case 'MdEventAvailable':
                return (<MdEventAvailable color="#E30614" size="36" />)
            case 'HiOutlineReceiptTax':
                return (<HiOutlineReceiptTax color="#E30614" size="36" />)
            case 'FaHistory':
                return (<FaHistory color="#E30614" size="36" />)
            case 'MdOutlinePendingActions':
                return (<MdOutlinePendingActions color="#E30614" size="36" />)
            case 'BsPiggyBank':
                return (<BsPiggyBank color="#E30614" size="36" />)
            case 'IoHelpBuoy':
                return (<IoHelpBuoy color="#E30614" size="36" />)
            case 'BsFillPlusCircleFill':
                return (<BsFillPlusCircleFill color="#E30614" size="36" />)
        }

    }

    function RenderizarMenu() {

        if (props.titulo == 'Notas Fiscais') {

            return (

                menuNotasFiscais.map((menu) => {
                    return (
                        <Col lg={3} md={4} style={{ padding: 15, display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => { ExecutaAcao(menu.identificador) }}>
                            <Card className="card-interno">
                                <Card.Body>
                                    <Card.Header style={{ height: '60px', backgroundColor: 'white', border: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <RenderizaIcone icone={menu.icone} />
                                    </Card.Header>
                                    <Card.Title style={{ textAlign: 'center', fontSize: 18 }}>{menu.titulo}</Card.Title>
                                </Card.Body>

                            </Card>
                        </Col>
                    )
                })
            )
        }

        if (props.titulo == 'Movimento Bancário') {

            return (

                menuMovimentoBancario.map((menu) => {
                    return (
                        <Col lg={3} md={4} style={{ padding: 15, display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => { ExecutaAcao(menu.identificador) }}>
                            <Card className="card-interno">
                                <Card.Body>
                                    <Card.Header style={{ height: '60px', backgroundColor: 'white', border: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <RenderizaIcone icone={menu.icone} />
                                    </Card.Header>
                                    <Card.Title style={{ textAlign: 'center', fontSize: 18 }}>{menu.titulo}</Card.Title>
                                </Card.Body>

                            </Card>
                        </Col>
                    )
                })
            )
        }

        if (props.titulo == 'Folha de Pagamento') {

            return (

                menuFolhaPgto.map((menu) => {
                    return (
                        <Col lg={3} md={4} style={{ padding: 15, display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => { ExecutaAcao(menu.identificador) }}>
                            <Card className="card-interno">
                                <Card.Body>
                                    <Card.Header style={{ height: '60px', backgroundColor: 'white', border: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <RenderizaIcone icone={menu.icone} />
                                    </Card.Header>
                                    <Card.Title style={{ textAlign: 'center', fontSize: 18 }}>{menu.titulo}</Card.Title>
                                </Card.Body>

                            </Card>
                        </Col>
                    )
                })
            )
        }

        if (props.titulo == 'Impostos') {

            return (

                menuImpostos.map((menu) => {
                    return (
                        <Col lg={3} md={4} style={{ padding: 15, display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => { ExecutaAcao(menu.identificador) }}>
                            <Card className="card-interno">
                                <Card.Body>
                                    <Card.Header style={{ height: '60px', backgroundColor: 'white', border: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <RenderizaIcone icone={menu.icone} />
                                    </Card.Header>
                                    <Card.Title style={{ textAlign: 'center', fontSize: 18 }}>{menu.titulo}</Card.Title>
                                </Card.Body>

                            </Card>
                        </Col>
                    )
                })
            )
        }

        if (props.titulo == 'Mais Opções') {

            return (

                menuMaisOpcoes.map((menu) => {
                    return (
                        <Col lg={3} md={4} style={{ padding: 15, display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => { ExecutaAcao(menu.identificador) }}>
                            <Card className="card-interno">
                                <Card.Body>
                                    <Card.Header style={{ height: '60px', backgroundColor: 'white', border: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <RenderizaIcone icone={menu.icone} />
                                    </Card.Header>
                                    <Card.Title style={{ textAlign: 'center', fontSize: 18 }}>{menu.titulo}</Card.Title>
                                </Card.Body>

                            </Card>
                        </Col>
                    )
                })
            )
        }

    }

    function ExecutaAcao(identificador) {
        switch (identificador) {
            case 'como_emitir_nota_fiscal':
                {
                    alert('Iniciando Consulta de Nota Fiscal')
                    alert('OK?')
                }
                break;
            case 'enviar_extrato':
                {
                    Navigate('/movimento-bancario/importar-extrato-ofx')
                }
                break;
            case 'solicitacoes':
                {
                    Navigate('/mais-opcoes/solicitacoes')
                }
                break;
            case 'listar_funcionarios':
                {
                    Navigate('/folha-pagamento/lista-funcionarios')
                }
                break;
        }
    }




    return (
        <>
            <Navegacao />
            <div style={{ display: 'flex', backgroundColor: '#f5f5f5', width: '100%', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                <Container style={{ display: 'flex', backgroundColor: '#f5f5f5', width: '100%', flexDirection: 'column', marginBottom: 80 }}>
                    <Col sm={12} lg={12} style={{ paddingTop: 20, paddingLeft: 25, paddingRight: 25 }}>
                        <Row>
                            <Col sm={12} lg={7} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom: 5 }}>
                                <h3>{props.titulo}</h3>
                            </Col>
                            <Col sm={12} lg={5} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginBottom: 5 }}>
                                <Button
                                    style={{ backgroundColor: '#E30613', border: 0 }}
                                    size="sm"
                                    onClick={() => {
                                        switch (props.titulo) {
                                            case 'Notas Fiscais':
                                                {
                                                    alert('Ajuda para Notas Fiscais')
                                                }
                                                break;
                                            case 'Impostos':
                                                {
                                                    alert('Ajuda para Impostos')
                                                }
                                                break;
                                            case 'Folha de Pagamento':
                                                {
                                                    alert('Ajuda para Folha de Pagamento')
                                                }
                                                break;
                                            case 'Movimento Bancário':
                                                {
                                                    alert('Ajuda para Movimento Bancário')
                                                }
                                                break;
                                        }
                                    }}
                                >
                                    Obter Ajuda
                                </Button>
                            </Col>
                        </Row>


                    </Col>
                    <Row style={{ paddingLeft: 40, paddingRight: 40 }}>
                        <hr />
                    </Row>
                    <div style={{ paddingLeft: 25, paddingRight: 25 }} >


                        <Row>
                            <RenderizarMenu />
                        </Row>

                    </div>

                </Container>
                <Footer />
            </div>

            <Modal show={loading} centered>
                <Modal.Body style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 24 }}>
                    <img
                        src={LogoArquivo}
                        width={'20%'}
                        alt={'Imagem de Nuvem'}
                        loading={"lazy"}
                    ></img>
                </Modal.Body>
                <Modal.Footer style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {msgLoading}
                </Modal.Footer>
            </Modal>

        </>

    )

}

export default PaginaDetalhe