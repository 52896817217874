import React, { useEffect, useState, useContext } from "react";
import Navegacao from "../../components/navbar";
import { GlobalContext } from '../../contexts/global'
import Footer from "../../components/footer";
import { useNavigate } from 'react-router-dom'
import { Col, Row, Container, Button, Modal } from 'react-bootstrap'
import LogoArquivo from '../../images/logo.svg';
import DataTable from "react-data-table-component";

function SolicitacoesUsuario() {

    const Navigate = useNavigate()

    const [loading, setLoading] = useState(false)
    const [msgLoading, setMsgLoading] = useState('')

    const [telaAlerta, setTelaAlerta] = useState(false)
    const [telaMsg, setTelaMsg] = useState('')
    const [telaTitulo, setTelaTitulo] = useState('')

    const { usuario, tipoAcesso, conexao, licenca } = useContext(GlobalContext)

    const [solicitacoes, setSolicitacoes] = useState([])


    function ExibirAlerta(titulo, mensagem) {
        setTelaMsg(mensagem)
        setTelaTitulo(titulo)
        setTelaAlerta(true)
    }


    function RenderizaSolicitacoes() {

        const colunas = [
            {
                name: 'Data',
                selector: row => row.data,
                sortable: true,
                maxWidth: '100px',
            },
            {
                name: 'Descrição',
                selector: row => row.descricao,
                sortable: true,
            },
            {
                name: 'Tipo',
                selector: row => row.es,
                sortable: true,
                maxWidth: '20px',

            },
            {
                name: 'Valor',
                selector: row => Number(row.valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
                sortable: true,
                maxWidth: '120px',
                right: true
            },
        ];

        const paginationComponentOptions = {
            rowsPerPageText: 'Registros por página',
            rangeSeparatorText: 'de',
            selectAllRowsItem: true,
            selectAllRowsItemText: 'Todos',
        };

        return (

            <>
                <Row style={{ marginTop: 20, paddingBottom: 80, textAlign: 'center' }}>
                    <Col>
                        <DataTable
                            columns={colunas}
                            data={solicitacoes}
                            // onRowClicked={(row, index) => {
                            //     GeraLogServidor(usuario.email, 'DASH', 'VENCIMENTOS', 'Clicou no Departamento: ' + row.departamento + ', Vencimento: ' + row.vencimento)
                            //     CarregaDetalheColetas(row.departamento, row.vencimento)
                            // }}
                            striped={true}
                            highlightOnHover={true}
                            responsive={true}
                            // pointerOnHover={true}
                            noDataComponent={"Nenhum Registro Encontrado =/"}
                            pagination={true}
                            paginationComponentOptions={paginationComponentOptions}
                        />
                    </Col>
                </Row>
            </>
        )
    }

    return (
        <>
            <Navegacao />

            <div style={{ display: 'flex', backgroundColor: '#f5f5f5', width: '100%', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                <Container style={{ display: 'flex', backgroundColor: '#f5f5f5', width: '100%', flexDirection: 'column' }}>
                    <Col sm={12} lg={12} style={{ paddingTop: 20, paddingLeft: 25, paddingRight: 25 }}>
                        <Row>
                            <Col sm={12} lg={7} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom: 5 }}>
                                <h3>Minhas Solicitações</h3>
                            </Col>
                            <Col sm={12} lg={5} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginBottom: 5 }}>
                                <Button
                                    style={{ backgroundColor: '#E30613', border: 0 }}
                                    size='sm'
                                    onClick={() => {
                                        alert('Mensagem Ajuda')
                                    }}
                                >
                                    Obter Ajuda
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                    <Row style={{ paddingLeft: 40, paddingRight: 40 }}>
                        <hr />
                    </Row>
                    <div style={{ paddingLeft: 25, paddingRight: 25 }} >
                        <Row>
                            <Col>
                                <Row>
                                    <Col>
                                        <Button
                                            style={{ backgroundColor: '#E30613', border: 0 }}
                                            size='sm'
                                            onClick={() => {
                                                alert('Vish')
                                            }}
                                        >
                                            Nova Solicitação
                                        </Button>
                                    </Col>
                                </Row>
                                <Row>
                                    <RenderizaSolicitacoes />
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </Container>
                <Footer />
                <Modal show={loading} centered>
                    <Modal.Body style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 24 }}>
                        <img
                            src={LogoArquivo}
                            width={'20%'}
                            alt={'Imagem de Nuvem'}
                            loading={'lazy'}
                        ></img>
                    </Modal.Body>
                    <Modal.Footer style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {msgLoading}
                    </Modal.Footer>
                </Modal>
                <Modal show={telaAlerta} centered>
                    <Modal.Header style={{ color: '#E30613', fontSize: 24 }}>
                        {telaTitulo}
                    </Modal.Header>
                    <Modal.Body style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {telaMsg}
                    </Modal.Body>
                    <Modal.Footer style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Button style={{ backgroundColor: '#E30613', border: 0 }} onClick={() => { setTelaAlerta(false) }}>OK</Button>
                    </Modal.Footer>
                </Modal>
            </div >
        </>
    )
}

export default SolicitacoesUsuario;