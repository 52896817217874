import React, { useState, createContext } from 'react'
import base64 from 'base-64'

export const GlobalContext = createContext({})

function GlobalProvider({ children }) {


    //Dados Login
    const [usuario, setUsuario] = useState({})
    const [tipoAcesso, setTipoAcesso] = useState('')

    //Dados Licenciamento
    const [licenca, setLicenca] = useState({})

    const [conexao] = useState({
        endereco: 'http://localhost:8082/api',
        autenticacao: 'Basic ' + base64.encode('admin:admin')
    })

    function SalvarLogin(email, razao, questor_empresa, questor_estabelecimento) {
        setUsuario({
            ...usuario, email: email
        })
        console.log('Login Salvo no ContextApi - ' + email)
    }

    function DefineTipoAcesso(email) {
        if (email.includes("@outbox.inf.br")) {
            setTipoAcesso('ADM')
            localStorage.removeItem('razao')
            localStorage.removeItem('empresa')
            localStorage.removeItem('estabelecimento')
            console.log('Tipo de Acesso Salvo no ContextApi - ' + email + ' - ADM')
        } else {
            setTipoAcesso('CLIENTE')
            console.log('Tipo de Acesso Salvo no ContextApi - ' + email + ' - CLIENTE')
        }
    }

    function DefineLicenca(dados) {
        setLicenca({
            ...licenca, empresas: dados.empresas
        })
    }

    return (
        <GlobalContext.Provider value={{ usuario, SalvarLogin, DefineTipoAcesso, tipoAcesso, conexao, DefineLicenca, licenca }}>
            {children}
        </GlobalContext.Provider>
    )
}

export default GlobalProvider;