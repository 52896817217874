import React, { useEffect, useState, useContext } from "react";
import Navegacao from "../../components/navbar";
import { GlobalContext } from '../../contexts/global'
import Footer from "../../components/footer";
import { useNavigate } from 'react-router-dom'
import { Col, Row, Container, Button, Modal, Form } from 'react-bootstrap'
import LogoArquivo from '../../images/logo.svg';
import DataTable from "react-data-table-component";
import { AiOutlinePlusSquare } from 'react-icons/ai'


function ListaFuncionarios() {

    const Navigate = useNavigate()

    const [loading, setLoading] = useState(false)
    const [msgLoading, setMsgLoading] = useState('')

    const [telaAlerta, setTelaAlerta] = useState(false)
    const [telaMsg, setTelaMsg] = useState('')
    const [telaTitulo, setTelaTitulo] = useState('')

    const { usuario, tipoAcesso, conexao, licenca } = useContext(GlobalContext)

    const [funcionarios, setFuncionarios] = useState([])

    const [telaAcoes, setTelaAcoes] = useState(false)
    const [nomeFuncionarioSelecionado, setNomeFuncionarioSelecionado] = useState(false)


    function ExibirAlerta(titulo, mensagem) {
        setTelaMsg(mensagem)
        setTelaTitulo(titulo)
        setTelaAlerta(true)
    }

    function ExibirAcoes(funcionario) {
        setNomeFuncionarioSelecionado(funcionario)
        setTelaAcoes(true)
    }
	

    function RenderizaFuncionarios() {

        const colunas = [
            {
                name: 'Código',
                selector: row => row.id,
                sortable: true,
                minWidth: '120px',
                maxWidth: '120px',
            },
            {
                name: 'Nome',
                selector: row => row.nome,
                sortable: true,
                minWidth: '200px',
            },
            {
                name: 'Admissão',
                selector: row => row.data_admissao,
                sortable: true,
                minWidth: '120px',
                maxWidth: '120px',
            },
            {
                name: 'Situação',
                selector: row => row.situacao,
                sortable: true,
                minWidth: '180px',
                maxWidth: '180px',
            },
            {
                name: 'Ações',
                button: true,
                style: {
                    '&:hover': {
                        cursor: 'pointer',
                    },
                },
                cell: row => (
                    < a onClick={() => {
                        ExibirAcoes(row.nome)
                    }} >
                        <AiOutlinePlusSquare size="24" color="#E30613" title="Ações" />
                    </a>
                ),
            },
        ];

        const paginationComponentOptions = {
            rowsPerPageText: 'Registros por página',
            rangeSeparatorText: 'de',
            selectAllRowsItem: true,
            selectAllRowsItemText: 'Todos',
        };

        return (

            <>

                <Row style={{ marginTop: 20, paddingBottom: 80, textAlign: 'center' }}>
                    <Col>
                        <DataTable
                            columns={colunas}
                            data={funcionarios}
                            // onRowClicked={(row, index) => {
                            //     GeraLogServidor(usuario.email, 'DASH', 'VENCIMENTOS', 'Clicou no Departamento: ' + row.departamento + ', Vencimento: ' + row.vencimento)
                            //     CarregaDetalheColetas(row.departamento, row.vencimento)
                            // }}
                            striped={true}
                            highlightOnHover={true}
                            responsive={true}
                            // pointerOnHover={true}
                            noDataComponent={"Nenhum Registro Encontrado =/"}
                            pagination={true}
                            paginationComponentOptions={paginationComponentOptions}
                        />
                    </Col>
                </Row>


            </>
        )
    }


    async function ListarFuncionarios(empresa) {
        setMsgLoading('Listando Funcionários')
        setLoading(true)

        const url = conexao.endereco + '/funcionarios/' + empresa

        let requisicao = new XMLHttpRequest()

        requisicao.timeout = 8000
        requisicao.open('GET', url, true)
        requisicao.setRequestHeader('Content-Type', 'application/json')
        requisicao.setRequestHeader('Authorization', conexao.autenticacao)
        requisicao.send()

        requisicao.ontimeout = function () {
            console.log('TimeOut na Requisição: ListarFuncionarios')
            setLoading(false)
            ExibirAlerta('Tempo Limite Excedido', 'Requisição: ListarFuncionarios')
        }

        requisicao.onerror = function () {
            console.log('Erro na Requisição: ListarFuncionarios: ' + this.response)
            setLoading(false)
            ExibirAlerta('Falha na Requisição', JSON.stringify(this.response))
        }

        requisicao.onload = function () {
            setLoading(false)
            console.log('RESPONSE: ' + this.response)
            let resposta = JSON.parse(this.response)
            if (requisicao.status === 200) {
                setFuncionarios(resposta)
            } else {
                alert('Falha ao Listar Funcionários')
            }

        }
    }

    useEffect(() => {
        const codigoEmpresa = localStorage.getItem('empresa')
        ListarFuncionarios(codigoEmpresa)
    }, [])




    return (
        <>
            <Navegacao />

            <div style={{ display: 'flex', backgroundColor: '#f5f5f5', width: '100%', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                <Container style={{ display: 'flex', backgroundColor: '#f5f5f5', width: '100%', flexDirection: 'column' }}>
                    <Col sm={12} lg={12} style={{ paddingTop: 20, paddingLeft: 25, paddingRight: 25 }}>
                        <Row>
                            <Col sm={12} lg={7} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom: 5 }}>
                                <h3>Lista de Funcionários</h3>
                            </Col>
                            <Col sm={12} lg={5} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginBottom: 5 }}>
                                <Button
                                    style={{ backgroundColor: '#E30613', border: 0 }}
                                    size='sm'
                                    onClick={() => {
                                        alert('Mensagem Ajuda')
                                    }}
                                >
                                    Obter Ajuda
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                    <Row style={{ paddingLeft: 40, paddingRight: 40 }}>
                        <hr />
                    </Row>
                    <div style={{ paddingLeft: 25, paddingRight: 25 }} >
                        <Row>
                            <RenderizaFuncionarios />
                        </Row>
                    </div>
                </Container>
                <Footer />
                <Modal show={loading} centered>
                    <Modal.Body style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 24 }}>
                        <img
                            src={LogoArquivo}
                            width={'20%'}
                            alt={'Imagem de Nuvem'}
                            loading={'lazy'}
                        ></img>
                    </Modal.Body>
                    <Modal.Footer style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {msgLoading}
                    </Modal.Footer>
                </Modal>
                <Modal show={telaAlerta} centered>
                    <Modal.Header style={{ color: '#E30613', fontSize: 24 }}>
                        {telaTitulo}
                    </Modal.Header>
                    <Modal.Body style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {telaMsg}
                    </Modal.Body>
                    <Modal.Footer style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Button style={{ backgroundColor: '#E30613', border: 0 }} onClick={() => { setTelaAlerta(false) }}>OK</Button>
                    </Modal.Footer>
                </Modal>
            </div>

            <Modal show={telaAcoes} centered>
                <Modal.Header style={{ color: '#E30613' }}>
                    <strong>{nomeFuncionarioSelecionado}</strong>
                </Modal.Header>
                <Modal.Body style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 24 }}>
                    <Form.Select aria-label="Default select example">
                        <option>Selecione a Ação</option>
                        <option value="1">Solicitar Férias</option>
                        <option value="2">Solicitar Cálculo Estimado de Rescisão</option>
                        <option value="3">Solicitar Rescisão</option>
                    </Form.Select>
                </Modal.Body>
                <Modal.Footer style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Button style={{ backgroundColor: '#000000', border: 0 }} onClick={() => { setTelaAcoes(false) }}>Fechar</Button>
                    <Button style={{ backgroundColor: '#E30613', border: 0 }} onClick={() => { setTelaAcoes(false) }}>Solicitar</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ListaFuncionarios;