import React, { useEffect, useState, useContext } from "react";
import Navegacao from "../../../components/navbar";
import { GlobalContext } from '../../../contexts/global'
import Footer from "../../../components/footer";
import { useNavigate, useLocation } from 'react-router-dom'
import { Col, Row, Container, Button, Modal, Form } from 'react-bootstrap'
import LogoArquivo from '../../../images/logo.svg';
import { useParams } from "react-router-dom";
import DataTable from 'react-data-table-component';
import { MdEdit, MdDelete } from 'react-icons/md'


function CadastroUsuarios() {


    const Navigate = useNavigate()
    const Location = useLocation()

    let params = useParams();

    const idEmpresa = Location.state.idEmpresa

    const [loading, setLoading] = useState(false)
    const [msgLoading, setMsgLoading] = useState('')
    const [telaAlerta, setTelaAlerta] = useState(false)
    const [telaMsg, setTelaMsg] = useState('')
    const [telaTitulo, setTelaTitulo] = useState('')
    const { usuario, tipoAcesso, conexao, licenca } = useContext(GlobalContext)

    const [dadosEmpresa, setDadosEmpresa] = useState([])
    const [usuarios, setUsuarios] = useState([])

    const [telaCadastro, setTelaCadastro] = useState(false)

    const [exibeSenha, setExibeSenha] = useState(false)

    const [modoEdicao, setModoEdicao] = useState(false)
    const [idExclusao, setIdExclusao] = useState('0')

    const [dadosCadastro, setDadosCadastro] = useState([])


    function ExibirAlerta(titulo, mensagem) {
        setTelaMsg(mensagem)
        setTelaTitulo(titulo)
        setTelaAlerta(true)
    }

    function ExibirExclusao(id) {
        setTelaTitulo('Confirma Exclusão?')
        setTelaMsg('A Ação não poderá ser desfeita!')
        setIdExclusao(id)
        setTelaAlerta(true)
    }

    function EditarCadastro(id, nome, email, cpf, id_empresa) {
        setExibeSenha(true)
        setDadosCadastro({
            id: id,
            nome: nome,
            email: email,
            cpf: cpf,
            id_empresa: id_empresa
        })
        setModoEdicao(true)
        setTelaCadastro(true)
    }

    function ExibeDados() {

        const colunas = [
            {
                name: 'E-mail',
                selector: row => row.email,
                sortable: true,
            },
            {
                name: 'Nome',
                selector: row => row.nome,
                sortable: true,
            },
            {
                name: 'CPF',
                selector: row => row.cpf,
                sortable: true,
                wrap: true
            },
            {
                name: 'Cadastro',
                selector: row => row.data_cadastro,
                sortable: true,
                wrap: true
            },
            {
                name: 'Ações',
                button: true,
                center: true,
                cell: row => (
                    <>
                        < a onClick={() => { EditarCadastro(row.id, row.nome, row.email, row.cpf, row.id_empresa) }} style={{ marginLeft: 5, cursor: 'pointer' }}>
                            <MdEdit size="24" color="#E30613" title="Editar" />
                        </a>
                        < a onClick={() => { ExibirExclusao(row.id) }} style={{ marginLeft: 5, cursor: 'pointer' }}>
                            <MdDelete size="24" color="#E30613" title="Excluir" />
                        </a>
                    </>

                ),
            },

        ];

        const paginationComponentOptions = {
            rowsPerPageText: 'Registros por página',
            rangeSeparatorText: 'de',
            selectAllRowsItem: true,
            selectAllRowsItemText: 'Todos',
        };


        return (
            <DataTable
                columns={colunas}
                data={usuarios}
                // onRowClicked={(row, index) => { Navigate('/adm/cadastros/empresas/' + row.cnpj, { state: { nome: row.nome, razao: row.razao } }) }}
                // expandOnRowClicked={true}
                striped={true}
                highlightOnHover={true}
                responsive={true}
                // pointerOnHover={true}
                noDataComponent={"Nenhum Registro Encontrado =/"}
                pagination={true}
                paginationComponentOptions={paginationComponentOptions}
            />
        )
    }

    async function AtualizarUsuario() {

        setTelaCadastro(false)

        setMsgLoading('Atualizando Cadastro do Usuário')
        setLoading(true)

        const url = conexao.endereco + '/Usuarios/' + dadosCadastro.id

        let body = {
            "nome": dadosCadastro.nome,
            "email": dadosCadastro.email,
            "cpf": dadosCadastro.cpf,
            "id_empresa": dadosCadastro.id_empresa
        }

        console.log('Atualização de Cadastro: ' + JSON.stringify(body))

        let requisicao = new XMLHttpRequest()

        requisicao.timeout = 8000
        requisicao.open('PUT', url, true)
        requisicao.setRequestHeader('Content-Type', 'application/json')
        requisicao.setRequestHeader('Authorization', conexao.autenticacao)
        requisicao.send(JSON.stringify(body))

        requisicao.ontimeout = function () {
            console.log('TimeOut na Requisição: ListarEmpresas')
            setLoading(false)
            ExibirAlerta('Tempo Limite Excedido', 'Requisição: ListarEmpresas')
        }

        requisicao.onerror = function () {
            console.log('Erro na Requisição: ListarEmpresas: ' + this.response)
            setLoading(false)
            ExibirAlerta('Falha na Requisição', 'Verifique Suporte')
        }

        requisicao.onloadend = function () {
            setLoading(false)
            console.log('RESPONSE: ' + this.response)
            if (requisicao.status === 200) {
                ExibirAlerta('Parabéns', 'Cadastro Atualizado Com Sucesso!')
                setDadosCadastro([])
                ListarUsuarios(idEmpresa)
            }
            if (requisicao.status === 400) {
                const resposta = JSON.parse(this.response)
                ExibirAlerta('Falha ao Cadastrar Empresa', resposta.status)
                setTelaCadastro(true)
            }
        }
    }

    async function CadastrarUsuario() {

        setTelaCadastro(false)

        setMsgLoading('Cadastrando Usuario')
        setLoading(true)

        const url = conexao.endereco + '/Usuarios/'

        let body = {
            "nome": dadosCadastro.nome,
            "cpf": dadosCadastro.cpf,
            "email": dadosCadastro.email,
            "id_empresa": idEmpresa
        }

        console.log('Cadastrando Usuário: ' + JSON.stringify(body))

        let requisicao = new XMLHttpRequest()

        requisicao.timeout = 8000
        requisicao.open('POST', url, true)
        requisicao.setRequestHeader('Content-Type', 'application/json')
        requisicao.setRequestHeader('Authorization', conexao.autenticacao)
        requisicao.send(JSON.stringify(body))

        requisicao.ontimeout = function () {
            console.log('TimeOut na Requisição: ListarEmpresas')
            setLoading(false)
            ExibirAlerta('Tempo Limite Excedido', 'Requisição: ListarEmpresas')
        }

        requisicao.onerror = function () {
            console.log('Erro na Requisição: ListarEmpresas: ' + this.response)
            setLoading(false)
            ExibirAlerta('Falha na Requisição', 'Verifique Suporte')
        }

        requisicao.onload = function () {
            setLoading(false)
            console.log('RESPONSE: ' + this.response)
            if (requisicao.status === 201) {
                ExibirAlerta('Parabéns', 'Cadastro Realizado Com Sucesso!')
                setDadosCadastro([])
                ListarUsuarios(idEmpresa)
            }
            if (requisicao.status === 400) {
                const resposta = JSON.parse(this.response)
                ExibirAlerta('Falha ao Cadastrar Empresa', resposta.status)
                setTelaCadastro(true)
            }
        }
    }

    async function DeletarEmpresa(id) {

        setTelaCadastro(false)
        setTelaAlerta(false)
        setMsgLoading('Deletando Cadastro da Empresa')
        setLoading(true)

        const url = conexao.endereco + '/Usuarios/' + id

        let requisicao = new XMLHttpRequest()

        requisicao.timeout = 8000
        requisicao.open('DELETE', url, true)
        requisicao.setRequestHeader('Content-Type', 'application/json')
        requisicao.setRequestHeader('Authorization', conexao.autenticacao)
        requisicao.send()

        requisicao.ontimeout = function () {
            console.log('TimeOut na Requisição: ListarEmpresas')
            setLoading(false)
            ExibirAlerta('Tempo Limite Excedido', 'Requisição: ListarEmpresas')
            setIdExclusao('0')
        }

        requisicao.onerror = function () {
            console.log('Erro na Requisição: ListarEmpresas: ' + this.response)
            setLoading(false)
            ExibirAlerta('Falha na Requisição', 'Verifique Suporte')
            setIdExclusao('0')
        }

        requisicao.onload = function () {
            setLoading(false)
            console.log('RESPONSE: ' + this.response)
            if (requisicao.status === 200) {
                ExibirAlerta('Parabéns', 'Cadastro Removido Com Sucesso!')
                setDadosCadastro([])
                setIdExclusao('0')
                ListarUsuarios(idEmpresa)
            }
            if (requisicao.status === 400) {
                const resposta = JSON.parse(this.response)
                ExibirAlerta('Falha ao Cadastrar Empresa', resposta.status)
                setIdExclusao('0')
            }
        }
    }

    async function ListarUsuarios(idInformado) {
        
        setMsgLoading('Listando Usuários')
        setLoading(true)

        const url = conexao.endereco + '/Usuarios/' + idInformado

        let requisicao = new XMLHttpRequest()

        requisicao.timeout = 8000
        requisicao.open('GET', url, true)
        requisicao.setRequestHeader('Content-Type', 'application/json')
        requisicao.setRequestHeader('Authorization', conexao.autenticacao)
        requisicao.send()

        requisicao.ontimeout = function () {
            console.log('TimeOut na Requisição: MvtoBancarioEnviaArquivoOFX')
            setLoading(false)
            ExibirAlerta('Tempo Limite Excedido', 'Requisição: MvtoBancarioEnviaArquivoOFX')
        }

        requisicao.onerror = function () {
            console.log('Erro na Requisição: MvtoBancarioEnviaArquivoOFX: ' + this.response)
            setLoading(false)
            ExibirAlerta('Falha na Requisição', JSON.stringify(this.response))
        }

        requisicao.onload = function () {
            setLoading(false)
            console.log('RESPONSE: ' + this.response)
            if (requisicao.status === 200) {
                setUsuarios(JSON.parse(this.response))
            }
            if (requisicao.status === 204) {
                setUsuarios([])
            }
            if (requisicao.status === 400) {
                ExibirAlerta('Falha ao Listar Usuários', JSON.stringify(this.response))
            }
        }
    }

    async function BuscarUsuarioPorEmail(idInformado) {

        setMsgLoading('Verificando Usuário Já Existente')
        setLoading(true)

        const url = conexao.endereco + '/Usuarios/' + idInformado

        let requisicao = new XMLHttpRequest()

        requisicao.timeout = 8000
        requisicao.open('GET', url, true)
        requisicao.setRequestHeader('Content-Type', 'application/json')
        requisicao.setRequestHeader('Authorization', conexao.autenticacao)
        requisicao.send()

        requisicao.ontimeout = function () {
            console.log('TimeOut na Requisição: MvtoBancarioEnviaArquivoOFX')
            setLoading(false)
            ExibirAlerta('Tempo Limite Excedido', 'Requisição: MvtoBancarioEnviaArquivoOFX')
        }

        requisicao.onerror = function () {
            console.log('Erro na Requisição: MvtoBancarioEnviaArquivoOFX: ' + this.response)
            setLoading(false)
            ExibirAlerta('Falha na Requisição', JSON.stringify(this.response))
        }

        requisicao.onload = function () {
            setLoading(false)
            console.log('RESPONSE: ' + this.response)
            if (requisicao.status === 200) {

                const resposta = JSON.parse(this.response)

                setDadosCadastro({ ...dadosCadastro, nome: resposta[0].nome, email: resposta[0].email, cpf: resposta[0].cpf })
                setExibeSenha(true)

            }
            if (requisicao.status === 204) {

            }
            if (requisicao.status === 400) {

            }
        }
    }



    useEffect(() => {
        // alert('Chegou no UseEffect')
        ListarUsuarios(idEmpresa)
        // GetEmpresa(params.cnpj)
    }, [])
    return (
        <>
            <Navegacao />


            {!loading &&
                <div style={{ display: 'flex', backgroundColor: '#f5f5f5', width: '100%', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                    <Container style={{ display: 'flex', backgroundColor: '#f5f5f5', width: '100%', flexDirection: 'column' }}>
                        <Col sm={12} lg={12} style={{ paddingTop: 20, paddingLeft: 25, paddingRight: 25 }}>
                            <Row>
                                <Col sm={12} lg={7} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom: 5 }}>
                                    <h3>{Location.state.razao}</h3>
                                </Col>
                                <Col sm={12} lg={5} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginBottom: 5 }}>
                                    <Button
                                        style={{ backgroundColor: '#E30613', border: 0 }}
                                        size='sm'
                                        onClick={() => {

                                            setModoEdicao(false)
                                            setDadosCadastro([])
                                            setTelaCadastro(true)

                                        }}
                                    >
                                        Incluir
                                    </Button>
                                    <Button
                                        style={{ backgroundColor: '#E30613', border: 0, marginLeft: 15 }}
                                        size='sm'
                                        onClick={() => {
                                            ListarUsuarios(idEmpresa)
                                        }}
                                    >
                                        Atualizar
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                        <Row style={{ paddingLeft: 40, paddingRight: 40 }}>
                            <hr />
                        </Row>
                        <div style={{ paddingLeft: 25, paddingRight: 25 }} >
                            <Row>
                                <ExibeDados />
                            </Row>
                        </div>
                    </Container>
                    <Footer />

                </div>
            }

            <Modal show={loading} centered>
                <Modal.Body style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 24 }}>
                    <img
                        src={LogoArquivo}
                        width={'20%'}
                        alt={'Imagem de Nuvem'}
                        loading={'lazy'}
                    ></img>
                </Modal.Body>
                <Modal.Footer style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {msgLoading}
                </Modal.Footer>
            </Modal>
            <Modal show={telaAlerta} centered>
                <Modal.Header style={{ color: '#E30613', fontSize: 24 }}>
                    {telaTitulo}
                </Modal.Header>
                <Modal.Body style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {telaMsg}
                </Modal.Body>
                <Modal.Footer style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {idExclusao === '0' &&
                        <Button style={{ backgroundColor: '#E30613', border: 0 }} onClick={() => { setTelaAlerta(false) }}>OK</Button>
                    }
                    {idExclusao !== '0' &&
                        <>
                            <Button variant="secondary" onClick={() => { setTelaAlerta(false) }}>Cancelar</Button>
                            <Button style={{ backgroundColor: '#E30613', border: 0 }} onClick={() => { DeletarEmpresa(idExclusao) }}>Confirmar</Button>
                        </>
                    }

                </Modal.Footer>
            </Modal>

            <Modal show={telaCadastro} dialogClassName='modal-50'>
                <Modal.Header>
                    {modoEdicao &&
                        <Modal.Title>Edição de Cadastro</Modal.Title>
                    }
                    {!modoEdicao &&
                        <Modal.Title>Cadastro de Usuário</Modal.Title>
                    }
                </Modal.Header>

                <Modal.Body>
                    <Form>
                        <Row>
                            <Col sm={12} lg={6}>
                                <Form.Group className="mb-3" controlId="formCadEmail">
                                    <Form.Label>E-mail</Form.Label>
                                    <Form.Control type="text" placeholder="E-mail" onBlur={() => { BuscarUsuarioPorEmail(dadosCadastro.email) }} value={dadosCadastro.email} onChange={(e) => { setDadosCadastro({ ...dadosCadastro, email: e.target.value }) }} />
                                </Form.Group>
                            </Col>
                            <Col sm={12} lg={6}>
                                <Form.Group className="mb-3" controlId="formCadNome">
                                    <Form.Label>Nome</Form.Label>
                                    <Form.Control type="text" placeholder="Nome" value={dadosCadastro.nome} onChange={(e) => { setDadosCadastro({ ...dadosCadastro, nome: e.target.value }) }} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} lg={6}>
                                <Form.Group className="mb-3" controlId="formCadCPF">
                                    <Form.Label>CPF</Form.Label>
                                    <Form.Control type="text" placeholder="CPF" value={dadosCadastro.cpf} onChange={(e) => { setDadosCadastro({ ...dadosCadastro, cpf: e.target.value }) }} />
                                </Form.Group>
                            </Col>
                            <Col sm={12} lg={6}>
                                <Form.Group className="mb-3" controlId="formCadSenha">
                                    <Form.Label>Senha</Form.Label>
                                    <Form.Control type="password" disabled={exibeSenha} placeholder="Senha" value={dadosCadastro.senha} onChange={(e) => { setDadosCadastro({ ...dadosCadastro, senha: e.target.value }) }} />
                                </Form.Group>
                            </Col>
                        </Row>

                    </Form>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={() => { setTelaCadastro(false) }}>Fechar</Button>
                    {modoEdicao &&
                        <Button style={{ backgroundColor: '#E30613', border: 0 }} onClick={() => { AtualizarUsuario() }}>Atualizar</Button>
                    }
                    {!modoEdicao &&
                        <Button style={{ backgroundColor: '#E30613', border: 0 }} onClick={() => { CadastrarUsuario() }}>Cadastrar</Button>
                    }
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default CadastroUsuarios;