import React, { useEffect, useState, useContext } from "react";
import Navegacao from "../../../components/navbar";
import { GlobalContext } from '../../../contexts/global'
import Footer from "../../../components/footer";
import { Col, Row, Container, Button, Modal } from 'react-bootstrap'
import LogoArquivo from '../../../images/logo.svg';

function Solicitacoes() {
    const [loading, setLoading] = useState(false)
    const [msgLoading, setMsgLoading] = useState('')
    const { usuario, tipoAcesso, conexao, licenca } = useContext(GlobalContext)
    return (
        <>
            <Navegacao />

            <div style={{ display: 'flex', backgroundColor: '#f5f5f5', width: '100%', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                <Container style={{ display: 'flex', backgroundColor: '#f5f5f5', width: '100%', flexDirection: 'column' }}>
                    <Col sm={12} lg={12} style={{ paddingTop: 20, paddingLeft: 25, paddingRight: 25 }}>
                        <Row>
                            <Col sm={12} lg={7} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom: 5 }}>
                                <h3>Solicitações</h3>
                            </Col>
                            <Col sm={12} lg={5} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginBottom: 5 }}>
                                <Button
                                    style={{ backgroundColor: '#E30613', border: 0 }}
                                    size='sm'
                                    onClick={() => {
                                        alert('Mensagem Ajuda')
                                    }}
                                >
                                    Obter Ajuda
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                    <Row style={{ paddingLeft: 40, paddingRight: 40 }}>
                        <hr />
                    </Row>
                    <div style={{ paddingLeft: 25, paddingRight: 25 }} >
                        <Row>
                            <h1>Página Modelo </h1>
                        </Row>
                    </div>
                </Container>
                <Footer />
                <Modal show={loading} centered>
                    <Modal.Body style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 24 }}>
                        <img
                            src={LogoArquivo}
                            width={'20%'}
                            alt={'Imagem de Nuvem'}
                            loading={'lazy'}
                        ></img>
                    </Modal.Body>
                    <Modal.Footer style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {msgLoading}
                    </Modal.Footer>
                </Modal>
            </div>
        </>
    )
}

export default Solicitacoes;