import React, { useContext, useState } from "react"
import Navegacao from "../components/navbar"
import { Container, Row, Col, Button, Card, Modal } from 'react-bootstrap'
import { GlobalContext } from '../contexts/global'
import { HiOutlineReceiptTax } from 'react-icons/hi'
import { FaHistory, FaSortAmountDownAlt } from 'react-icons/fa'
import { VscAccount } from 'react-icons/vsc'
import { BsCheckAll, BsFillPersonFill } from 'react-icons/bs'
import { BiImport } from 'react-icons/bi'
import { AiOutlineUnorderedList, AiOutlineBank, AiOutlineCheckCircle, AiOutlineCalendar } from 'react-icons/ai'
import { ImFilesEmpty } from 'react-icons/im'
import { HiOutlineDocumentReport } from 'react-icons/hi'
import LogoArquivo from '../images/logo.svg';
import Footer from "../components/footer"

function Home() {

    const { usuario, tipoAcesso, licenca } = useContext(GlobalContext)
    const [loading, setLoading] = useState(false)
    const [msgLoading, setMsgLoading] = useState('')

    return (
        <>

            <Navegacao />

            {!usuario.email &&
                <Container fluid style={{ padding: 0, margin: 0 }}>
                    <div>
                        <img
                            style={{ objectFit: 'cover', width: '100%', height: '100%' }}
                            src={require('../images/background.jpeg')}
                            alt={'Background'}
                            loading={"lazy"}
                        ></img>
                    </div>
                </Container>
            }
            {usuario.email &&
                <>

                    <Container style={{ display: 'flex', backgroundColor: '#f5f5f5', width: '100%', flexDirection: 'column', marginBottom: 80 }}>

                        <Row sm={12} lg={12} style={{ paddingTop: 20, paddingLeft: 25, paddingRight: 25 }}>
                            <Col sm={12} lg={7} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom: 5 }}>
                                <h3>Olá, {usuario.email}</h3>
                            </Col>
                            <Col sm={12} lg={5} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginBottom: 5 }}>
                                <Button
                                    style={{ backgroundColor: '#E30613', border: 0 }}
                                    size="sm"
                                    onClick={() => {
                                        alert(licenca.qtdeClientes)
                                    }}
                                >
                                    Obter Ajuda
                                </Button>
                            </Col>
                        </Row>
                        <Row style={{ paddingLeft: 40, paddingRight: 40 }}>
                            <hr />
                        </Row>

                        {tipoAcesso === 'CLIENTE' &&

                            <div style={{ paddingLeft: 25, paddingRight: 25 }} > {/* DIV DOS CARDS */}

                                <Row>
                                    <Col lg={6} sm={12} style={{ padding: 15 }}>
                                        <Card style={{ width: '100%', minHeight: 260 }}>
                                            <Card.Body>
                                                <Card.Header style={{ backgroundColor: 'white', color: '#E30613', fontSize: 24 }}>
                                                    <HiOutlineReceiptTax size="32" color="#E30613" />   Impostos   <strong style={{ color: 'grey', fontSize: 14 }}>Maio de 2022</strong>
                                                </Card.Header>
                                                <Card.Title style={{ display: 'flex', justifyContent: 'flex-end', color: '#E30614', fontSize: 36, marginTop: 20 }}>
                                                    R$ 1.042,46
                                                </Card.Title>
                                                <Card.Subtitle style={{ display: 'flex', justifyContent: 'flex-end', color: 'grey', fontSize: 14, marginBottom: 20 }}>
                                                    Aguardando Pagamento
                                                </Card.Subtitle>
                                                <Card.Footer style={{ display: 'flex', justifyContent: 'flex-end', color: '#E30614', backgroundColor: 'white', paddingTop: 20 }}>
                                                    <Button
                                                        style={{ backgroundColor: 'white', color: '#E30613', borderColor: '#E30613' }}
                                                        size="sm"
                                                        onClick={() => {
                                                            setMsgLoading('Processando Solicitação')
                                                            setLoading(true)
                                                        }}
                                                    >
                                                        Detalhar
                                                    </Button>
                                                </Card.Footer>

                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col lg={6} sm={12} style={{ padding: 15 }}>
                                        <Card style={{ width: '100%', minHeight: 260 }}>
                                            <Card.Body>
                                                <Card.Header style={{ backgroundColor: 'white', color: '#E30613', fontSize: 24 }}>
                                                    <FaHistory size="24" color="#E30613" />   Rotinas   <strong style={{ color: 'grey', fontSize: 14 }}>Maio de 2022</strong>
                                                </Card.Header>
                                                <Card.Title style={{ display: 'flex', justifyContent: 'flex-start', color: '#E30614', marginTop: 20, marginBottom: 22 }}>
                                                    Próxima Rotina:
                                                </Card.Title>
                                                <Card.Subtitle style={{ display: 'flex', justifyContent: 'flex-start', color: 'grey', fontSize: 18, marginBottom: 20 }}>
                                                    Dia 25 - Envio dos Extratos Bancários
                                                </Card.Subtitle>
                                                <Card.Footer style={{ display: 'flex', justifyContent: 'flex-end', color: '#E30614', backgroundColor: 'white', paddingTop: 20 }}>
                                                    <Button
                                                        style={{ backgroundColor: 'white', color: '#E30613', borderColor: '#E30613' }}
                                                        size="sm"
                                                        onClick={() => {
                                                            alert('Detalhar Impostos')
                                                        }}
                                                    >
                                                        Ver Todas as Rotinas
                                                    </Button>
                                                </Card.Footer>

                                            </Card.Body>
                                        </Card>
                                    </Col>

                                </Row>

                                <Row>
                                    <Col lg={12} sm={12} style={{ padding: 15 }}>
                                        <Card style={{ width: '100%' }}>
                                            <Card.Body>
                                                <Card.Header style={{ backgroundColor: 'white', color: '#E30613', fontSize: 24 }}>
                                                    <BsCheckAll size="32" color="#E30613" />   Acesso Rápido
                                                </Card.Header>
                                                <Row style={{ padding: 10 }}>
                                                    <Col style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 20 }}>
                                                        <Card className="card-home">
                                                            <Card.Body>
                                                                <Card.Header style={{ height: '60px', backgroundColor: 'white', border: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                    <BiImport color="#E30614" size="36" />
                                                                </Card.Header>
                                                                <Card.Title style={{ textAlign: 'center', fontSize: 12 }}>Importar Notas Fiscais</Card.Title>
                                                            </Card.Body>

                                                        </Card>
                                                    </Col>
                                                    <Col style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 20 }}>
                                                        <Card className="card-home">
                                                            <Card.Body>
                                                                <Card.Header style={{ height: '60px', backgroundColor: 'white', border: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                    <AiOutlineUnorderedList color="#E30614" size="36" />
                                                                </Card.Header>
                                                                <Card.Title style={{ textAlign: 'center', fontSize: 12 }}>Consultar Notas Fiscais</Card.Title>
                                                            </Card.Body>

                                                        </Card>
                                                    </Col>
                                                    <Col style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 20 }}>
                                                        <Card className="card-home">
                                                            <Card.Body>
                                                                <Card.Header style={{ height: '60px', backgroundColor: 'white', border: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                    <HiOutlineReceiptTax color="#E30614" size="36" />
                                                                </Card.Header>
                                                                <Card.Title style={{ textAlign: 'center', fontSize: 12 }}>Impostos Pendentes</Card.Title>
                                                            </Card.Body>

                                                        </Card>
                                                    </Col>
                                                    <Col style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 20 }}>
                                                        <Card className="card-home">
                                                            <Card.Body>
                                                                <Card.Header style={{ height: '60px', backgroundColor: 'white', border: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                    <AiOutlineBank color="#E30614" size="36" />
                                                                </Card.Header>
                                                                <Card.Title style={{ textAlign: 'center', fontSize: 12 }}>Movimento Bancário</Card.Title>
                                                            </Card.Body>

                                                        </Card>
                                                    </Col>
                                                    <Col style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 20 }}>
                                                        <Card className="card-home">
                                                            <Card.Body>
                                                                <Card.Header style={{ height: '60px', backgroundColor: 'white', border: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                    <ImFilesEmpty color="#E30614" size="36" />
                                                                </Card.Header>
                                                                <Card.Title style={{ textAlign: 'center', fontSize: 12 }}>Transferir Arquivos</Card.Title>
                                                            </Card.Body>

                                                        </Card>
                                                    </Col>
                                                    <Col style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 20 }}>
                                                        <Card className="card-home">
                                                            <Card.Body>
                                                                <Card.Header style={{ height: '60px', backgroundColor: 'white', border: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                    <HiOutlineDocumentReport color="#E30614" size="36" />
                                                                </Card.Header>
                                                                <Card.Title style={{ textAlign: 'center', fontSize: 12 }}>Relatório Gerencial DRE</Card.Title>
                                                            </Card.Body>

                                                        </Card>
                                                    </Col>


                                                </Row>


                                            </Card.Body>
                                        </Card>
                                    </Col>


                                </Row>
                            </div>
                        }

                        {tipoAcesso === 'ADM' &&



                            <div style={{ paddingLeft: 25, paddingRight: 25 }} > {/* DIV DOS CARDS */}

                                <Row sm={12} lg={12} style={{ paddingTop: 30 }} >
                                    <Col sm={12} lg={7} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom: 5 }}>
                                        <h5>Solicitações</h5><strong style={{ color: 'grey', fontSize: 14, marginLeft: 15 }}>Junho de 2022</strong>
                                    </Col>
                                    <Col sm={12} lg={5} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginBottom: 5 }}>

                                        <AiOutlineCalendar
                                            size="32" color="#E30613"
                                            style={{ cursor: 'pointer' }}
                                            alt="Vish"
                                            onClick={() => {
                                                alert(tipoAcesso)
                                            }}
                                        />

                                    </Col>
                                    <Row style={{ paddingLeft: 25, paddingRight: 5 }}>
                                        <hr />
                                    </Row>
                                </Row>

                                <Row>
                                    <Col lg={3} sm={12} style={{ padding: 15 }}>
                                        <Card style={{ width: '100%', minHeight: 260 }} className="card-solicitacao">
                                            <Card.Body>
                                                <Card.Header style={{ backgroundColor: 'white', color: 'black', fontSize: 20 }}>
                                                    <FaSortAmountDownAlt size="32" color="black" /> Recebidas
                                                </Card.Header>
                                                <Card.Title style={{ display: 'flex', justifyContent: 'center', color: 'black', fontSize: 72, marginTop: 20 }}>
                                                    76
                                                </Card.Title>
                                                <Card.Footer style={{ display: 'flex', justifyContent: 'flex-end', color: 'black', backgroundColor: 'white', paddingTop: 20 }}>
                                                    <Button
                                                        style={{ backgroundColor: 'white', color: 'black', borderColor: 'black' }}
                                                        size="sm"
                                                        onClick={() => {
                                                            setMsgLoading('Processando Solicitação')
                                                            setLoading(true)
                                                        }}
                                                    >
                                                        Detalhar
                                                    </Button>
                                                </Card.Footer>

                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col lg={3} sm={12} style={{ padding: 15 }}>
                                        <Card style={{ width: '100%', minHeight: 260 }} className="card-solicitacao">
                                            <Card.Body>
                                                <Card.Header style={{ backgroundColor: 'white', color: '#20699F', fontSize: 20 }}>
                                                    <BsFillPersonFill size="32" color="#20699F" /> Cliente
                                                </Card.Header>
                                                <Card.Title style={{ display: 'flex', justifyContent: 'center', color: '#20699F', fontSize: 72, marginTop: 20 }}>
                                                    60
                                                </Card.Title>
                                                <Card.Footer style={{ display: 'flex', justifyContent: 'flex-end', color: '#20699F', backgroundColor: 'white', paddingTop: 20 }}>
                                                    <Button
                                                        style={{ backgroundColor: 'white', color: '#20699F', borderColor: '#20699F' }}
                                                        size="sm"
                                                        onClick={() => {
                                                            setMsgLoading('Processando Solicitação')
                                                            setLoading(true)
                                                        }}
                                                    >
                                                        Detalhar
                                                    </Button>
                                                </Card.Footer>

                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col lg={3} sm={12} style={{ padding: 15 }}>
                                        <Card style={{ width: '100%', minHeight: 260 }} className="card-solicitacao">
                                            <Card.Body>
                                                <Card.Header style={{ backgroundColor: 'white', color: '#E30613', fontSize: 20 }}>
                                                    <VscAccount size="32" color="#E30613" /> Escritório
                                                </Card.Header>
                                                <Card.Title style={{ display: 'flex', justifyContent: 'center', color: '#E30614', fontSize: 72, marginTop: 20 }}>
                                                    10
                                                </Card.Title>
                                                <Card.Footer style={{ display: 'flex', justifyContent: 'flex-end', color: '#E30614', backgroundColor: 'white', paddingTop: 20 }}>
                                                    <Button
                                                        style={{ backgroundColor: 'white', color: '#E30613', borderColor: '#E30613' }}
                                                        size="sm"
                                                        onClick={() => {
                                                            setMsgLoading('Processando Solicitação')
                                                            setLoading(true)
                                                        }}
                                                    >
                                                        Detalhar
                                                    </Button>
                                                </Card.Footer>

                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col lg={3} sm={12} style={{ padding: 15 }}>
                                        <Card style={{ width: '100%', minHeight: 260 }} className="card-solicitacao">
                                            <Card.Body>
                                                <Card.Header style={{ backgroundColor: 'white', color: '#007D00', fontSize: 20 }}>
                                                    <AiOutlineCheckCircle size="32" color="#007D00" /> Finalizados
                                                </Card.Header>
                                                <Card.Title style={{ display: 'flex', justifyContent: 'center', color: '#007D00', fontSize: 72, marginTop: 20 }}>
                                                    6
                                                </Card.Title>
                                                <Card.Footer style={{ display: 'flex', justifyContent: 'flex-end', color: '#007D00', backgroundColor: 'white', paddingTop: 20 }}>
                                                    <Button
                                                        style={{ backgroundColor: 'white', color: '#007D00', borderColor: '#007D00' }}
                                                        size="sm"
                                                        onClick={() => {
                                                            setMsgLoading('Processando Solicitação')
                                                            setLoading(true)
                                                        }}
                                                    >
                                                        Detalhar
                                                    </Button>
                                                </Card.Footer>

                                            </Card.Body>
                                        </Card>
                                    </Col>


                                </Row>

                                <Row>
                                    <Col lg={12} sm={12} style={{ padding: 15 }}>
                                        <Card style={{ width: '100%' }}>
                                            <Card.Body>
                                                <Card.Header style={{ backgroundColor: 'white', color: '#E30613', fontSize: 24 }}>
                                                    <BsCheckAll size="32" color="#E30613" />   Acesso Rápido
                                                </Card.Header>
                                                <Row style={{ padding: 10 }}>
                                                    <Col style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 20 }}>
                                                        <Card className="card-home">
                                                            <Card.Body>
                                                                <Card.Header style={{ height: '60px', backgroundColor: 'white', border: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                    <BiImport color="#E30614" size="36" />
                                                                </Card.Header>
                                                                <Card.Title style={{ textAlign: 'center', fontSize: 12 }}>Importar Notas Fiscais</Card.Title>
                                                            </Card.Body>

                                                        </Card>
                                                    </Col>
                                                    <Col style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 20 }}>
                                                        <Card className="card-home">
                                                            <Card.Body>
                                                                <Card.Header style={{ height: '60px', backgroundColor: 'white', border: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                    <AiOutlineUnorderedList color="#E30614" size="36" />
                                                                </Card.Header>
                                                                <Card.Title style={{ textAlign: 'center', fontSize: 12 }}>Consultar Notas Fiscais</Card.Title>
                                                            </Card.Body>

                                                        </Card>
                                                    </Col>
                                                    <Col style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 20 }}>
                                                        <Card className="card-home">
                                                            <Card.Body>
                                                                <Card.Header style={{ height: '60px', backgroundColor: 'white', border: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                    <HiOutlineReceiptTax color="#E30614" size="36" />
                                                                </Card.Header>
                                                                <Card.Title style={{ textAlign: 'center', fontSize: 12 }}>Impostos Pendentes</Card.Title>
                                                            </Card.Body>

                                                        </Card>
                                                    </Col>
                                                    <Col style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 20 }}>
                                                        <Card className="card-home">
                                                            <Card.Body>
                                                                <Card.Header style={{ height: '60px', backgroundColor: 'white', border: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                    <AiOutlineBank color="#E30614" size="36" />
                                                                </Card.Header>
                                                                <Card.Title style={{ textAlign: 'center', fontSize: 12 }}>Movimento Bancário</Card.Title>
                                                            </Card.Body>

                                                        </Card>
                                                    </Col>
                                                    <Col style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 20 }}>
                                                        <Card className="card-home">
                                                            <Card.Body>
                                                                <Card.Header style={{ height: '60px', backgroundColor: 'white', border: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                    <ImFilesEmpty color="#E30614" size="36" />
                                                                </Card.Header>
                                                                <Card.Title style={{ textAlign: 'center', fontSize: 12 }}>Transferir Arquivos</Card.Title>
                                                            </Card.Body>

                                                        </Card>
                                                    </Col>
                                                    <Col style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 20 }}>
                                                        <Card className="card-home">
                                                            <Card.Body>
                                                                <Card.Header style={{ height: '60px', backgroundColor: 'white', border: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                    <HiOutlineDocumentReport color="#E30614" size="36" />
                                                                </Card.Header>
                                                                <Card.Title style={{ textAlign: 'center', fontSize: 12 }}>Relatório Gerencial DRE</Card.Title>
                                                            </Card.Body>

                                                        </Card>
                                                    </Col>


                                                </Row>


                                            </Card.Body>
                                        </Card>
                                    </Col>


                                </Row>
                            </div>
                        }

                    </Container>
                    <Footer />
                </>

            }




            <Modal show={loading} centered >
                <Modal.Body style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 24 }}>
                    {/* <Spinner animation="border" style={{ color: "#E30613" }} /> */}
                    <img
                        src={LogoArquivo}
                        width={'20%'}
                        alt={'Imagem de Nuvem'}
                        loading={"lazy"}
                    ></img>
                </Modal.Body>
                <Modal.Footer style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {msgLoading}
                </Modal.Footer>
            </Modal>





        </>
    )
}

export default Home