import React, { useEffect, useState, useContext } from "react";
import Navegacao from "../components/navbar";
import { GlobalContext } from '../contexts/global'
import Footer from "../components/footer";
import { Col, Row, Container, Button, Modal } from 'react-bootstrap'
import LogoArquivo from '../images/logo.svg';

function NaoEncontrado() {
    const [loading, setLoading] = useState(false)
    const [msgLoading, setMsgLoading] = useState('')
    const { usuario, tipoAcesso, conexao, licenca } = useContext(GlobalContext)
    return (
        <>
            <Navegacao />
''
            <div style={{ display: 'flex', backgroundColor: '#f5f5f5', width: '100%', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                <Container style={{ display: 'flex', backgroundColor: '#f5f5f5', width: '100%', flexDirection: 'column', width: '30%' }}>
                    <Row style={{ padding: 50, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <img
                            src={LogoArquivo}
                            width={'30%'}
                            alt={'Imagem de Nuvem'}
                            loading={"lazy"}
                        ></img>
                    </Row>
                    <Row>
                        <hr />
                    </Row>
                    <Row style={{ textAlign: 'center' }}>
                        <h1>Página Não Encontrada</h1>
                    </Row>
                </Container>
                <Footer />
                <Modal show={loading} centered>
                    <Modal.Body style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 24 }}>
                        <img
                            src={LogoArquivo}
                            width={'20%'}
                            alt={'Imagem de Nuvem'}
                            loading={'lazy'}
                        ></img>
                    </Modal.Body>
                    <Modal.Footer style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {msgLoading}
                    </Modal.Footer>
                </Modal>
            </div>
        </>
    )
}

export default NaoEncontrado;